"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleLE = void 0;
var client_1 = require("../../client");
var aoHost_1 = require("../../client/aoHost");
var encoding_1 = require("../../encoding");
/**
 * Handles incoming evidence list, all evidences at once
 * item per packet.
 *
 * @param {Array} args packet arguments
 */
var handleLE = function (args) {
    client_1.client.evidences = [];
    for (var i = 1; i < args.length; i++) {
        if (!args[i].includes("&"))
            break;
        var arg = args[i].split("&");
        client_1.client.evidences[i - 1] = {
            name: (0, encoding_1.prepChat)(arg[0]),
            desc: (0, encoding_1.prepChat)(arg[1]),
            filename: (0, encoding_1.safeTags)(arg[2]),
            icon: "".concat(aoHost_1.AO_HOST, "evidence/").concat(encodeURI(arg[2].toLowerCase())),
        };
    }
    var evidence_box = document.getElementById("evidences");
    evidence_box.innerHTML = "";
    var _loop_1 = function (i) {
        var evi_item = new Image();
        evi_item.id = "evi_" + i;
        evi_item.className = "evi_icon";
        evi_item.src = client_1.client.evidences[i].icon;
        evi_item.alt = client_1.client.evidences[i].name;
        evi_item.onclick = function () { window.pickEvidence(i); };
        evidence_box.appendChild(evi_item);
    };
    for (var i = 0; i <= client_1.client.evidences.length - 1; i++) {
        _loop_1(i);
    }
};
exports.handleLE = handleLE;
