"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendMusicChange = void 0;
var client_1 = require("../../client");
/**
 * Requests to change the music to the specified track.
 * @param {string} track the track ID
 */
var sendMusicChange = function (track) {
    client_1.client.sender.sendServer("MC#".concat(track, "#").concat(client_1.client.charID, "#%"));
};
exports.sendMusicChange = sendMusicChange;
