"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initTestimonyUpdater = void 0;
var client_1 = require("../../client");
/**
 * Intialize testimony updater
 */
var initTestimonyUpdater = function () {
    var testimonyFilenames = {
        1: "witnesstestimony",
        2: "crossexamination",
        3: "notguilty",
        4: "guilty",
    };
    var testimony = testimonyFilenames[client_1.client.testimonyID];
    if (!testimony) {
        console.warn("Invalid testimony ID ".concat(client_1.client.testimonyID));
        return;
    }
    client_1.client.viewport.testimonyAudio.src = client_1.client.resources[testimony].sfx;
    client_1.client.viewport.testimonyAudio.play();
    var testimonyOverlay = (document.getElementById("client_testimony"));
    testimonyOverlay.src = client_1.client.resources[testimony].src;
    testimonyOverlay.style.opacity = "1";
    client_1.client.viewport.setTestimonyTimer(0);
    client_1.client.viewport.setTestimonyUpdater(setTimeout(function () { return client_1.client.viewport.updateTestimony(); }, client_1.UPDATE_INTERVAL));
};
exports.initTestimonyUpdater = initTestimonyUpdater;
