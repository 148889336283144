"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeBlipVolume = void 0;
var setCookie_1 = require("../utils/setCookie");
var client_1 = require("../client");
/**
 * Triggered by the blip volume slider.
 */
var changeBlipVolume = function () {
    var blipVolume = (document.getElementById("client_bvolume")).value;
    client_1.client.viewport.blipChannels.forEach(function (channel) { return (channel.volume = Number(blipVolume)); });
    (0, setCookie_1.default)("blipVolume", blipVolume);
};
exports.changeBlipVolume = changeBlipVolume;
window.changeBlipVolume = exports.changeBlipVolume;
