"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleTI = void 0;
/**
 * Handles a timer update
 * @param {Array} args packet arguments
 */
var handleTI = function (args) {
    var timerid = Number(args[1]);
    var type = Number(args[2]);
    var timer_value = args[3];
    switch (type) {
        case 0:
        //
        case 1:
            document.getElementById("client_timer".concat(timerid)).innerText =
                timer_value;
        case 2:
            document.getElementById("client_timer".concat(timerid)).style.display = "";
        case 3:
            document.getElementById("client_timer".concat(timerid)).style.display =
                "none";
    }
};
exports.handleTI = handleTI;
