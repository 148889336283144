"use strict";
/* eslint indent: ["error", 2, { "SwitchCase": 1 }] */
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleMS = void 0;
var client_1 = require("../../client");
var handleCharacterInfo_1 = require("../../client/handleCharacterInfo");
var resetICParams_1 = require("../../client/resetICParams");
var encoding_1 = require("../../encoding");
var handleICSpeaking_1 = require("../../viewport/utils/handleICSpeaking");
/**
   * Handles an in-character chat message.
   * @param {*} args packet arguments
   */
var handleMS = function (args) {
    // TODO: this if-statement might be a bug.
    if (args[4] !== client_1.client.viewport.getChatmsg().content) {
        document.getElementById("client_inner_chat").innerHTML = "";
        var char_id = Number(args[9]);
        var char_name = (0, encoding_1.safeTags)(args[3]);
        var msg_nameplate = args[3];
        var msg_blips = "male";
        var char_chatbox = "default";
        var char_muted = false;
        if (char_id < client_1.client.char_list_length && char_id >= 0) {
            if (client_1.client.chars[char_id].name !== char_name) {
                console.info("".concat(client_1.client.chars[char_id].name, " is iniediting to ").concat(char_name));
                var chargs = ("".concat(char_name, "&") + "iniediter").split("&");
                (0, handleCharacterInfo_1.handleCharacterInfo)(chargs, char_id);
            }
        }
        try {
            msg_nameplate = client_1.client.chars[char_id].showname;
        }
        catch (e) {
            msg_nameplate = args[3];
        }
        try {
            msg_blips = client_1.client.chars[char_id].blips;
        }
        catch (e) { }
        try {
            char_chatbox = client_1.client.chars[char_id].chat;
        }
        catch (e) {
            char_chatbox = "default";
        }
        try {
            char_muted = client_1.client.chars[char_id].muted;
        }
        catch (e) {
            char_muted = false;
            console.error("we're still missing some character data");
        }
        if (char_muted === false) {
            var chatmsg = {
                deskmod: Number((0, encoding_1.safeTags)(args[1]).toLowerCase()),
                preanim: (0, encoding_1.safeTags)(args[2]).toLowerCase(), // get preanim
                nameplate: msg_nameplate,
                chatbox: char_chatbox,
                name: char_name,
                sprite: (0, encoding_1.safeTags)(args[4]).toLowerCase(),
                content: (0, encoding_1.prepChat)(args[5]), // Escape HTML tags
                side: args[6].toLowerCase(),
                sound: (0, encoding_1.safeTags)(args[7]).toLowerCase(),
                blips: (0, encoding_1.safeTags)(msg_blips),
                type: Number(args[8]),
                charid: char_id,
                snddelay: Number(args[10]),
                objection: Number(args[11]),
                evidence: Number((0, encoding_1.safeTags)(args[12])),
                flip: Number(args[13]),
                flash: Number(args[14]),
                color: Number(args[15]),
                speed: client_1.UPDATE_INTERVAL,
            };
            if (args.length > 16) {
                var extra_cccc = {
                    showname: (0, encoding_1.prepChat)(args[16]),
                    other_charid: Number(args[17]),
                    other_name: (0, encoding_1.safeTags)(args[18]),
                    other_emote: (0, encoding_1.safeTags)(args[19]),
                    self_offset: args[20].split("<and>"), // HACK: here as well, client is fucked and uses this instead of &
                    other_offset: args[21].split("<and>"),
                    other_flip: Number(args[22]),
                    noninterrupting_preanim: Number(args[23]),
                };
                chatmsg = Object.assign(extra_cccc, chatmsg);
                if (args.length > 24) {
                    var extra_27 = {
                        looping_sfx: Number(args[24]),
                        screenshake: Number(args[25]),
                        frame_screenshake: (0, encoding_1.safeTags)(args[26]),
                        frame_realization: (0, encoding_1.safeTags)(args[27]),
                        frame_sfx: (0, encoding_1.safeTags)(args[28]),
                    };
                    chatmsg = Object.assign(extra_27, chatmsg);
                    if (args.length > 29) {
                        var extra_28 = {
                            additive: Number(args[29]),
                            effects: args[30].split("|"),
                        };
                        chatmsg = Object.assign(extra_28, chatmsg);
                    }
                    else {
                        var extra_28 = {
                            additive: 0,
                            effects: ["", "", ""],
                        };
                        chatmsg = Object.assign(extra_28, chatmsg);
                    }
                }
                else {
                    var extra_27 = {
                        looping_sfx: 0,
                        screenshake: 0,
                        frame_screenshake: "",
                        frame_realization: "",
                        frame_sfx: "",
                    };
                    chatmsg = Object.assign(extra_27, chatmsg);
                    var extra_28 = {
                        additive: 0,
                        effects: ["", "", ""],
                    };
                    chatmsg = Object.assign(extra_28, chatmsg);
                }
            }
            else {
                var extra_cccc = {
                    showname: "",
                    other_charid: 0,
                    other_name: "",
                    other_emote: "",
                    self_offset: [0, 0],
                    other_offset: [0, 0],
                    other_flip: 0,
                    noninterrupting_preanim: 0,
                };
                chatmsg = Object.assign(extra_cccc, chatmsg);
                var extra_27 = {
                    looping_sfx: 0,
                    screenshake: 0,
                    frame_screenshake: "",
                    frame_realization: "",
                    frame_sfx: "",
                };
                chatmsg = Object.assign(extra_27, chatmsg);
                var extra_28 = {
                    additive: 0,
                    effects: ["", "", ""],
                };
                chatmsg = Object.assign(extra_28, chatmsg);
            }
            if (chatmsg.content.trim() === "") {
                //blankpost
                chatmsg.content = "";
                // empty string as chatbox means hide it
                chatmsg.chatbox = "";
            }
            // our own message appeared, reset the buttons
            if (chatmsg.charid === client_1.client.charID) {
                (0, resetICParams_1.resetICParams)();
            }
            (0, handleICSpeaking_1.handle_ic_speaking)(chatmsg); // no await
        }
    }
};
exports.handleMS = handleMS;
