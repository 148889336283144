"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleFA = void 0;
var client_1 = require("../../client");
var createArea_1 = require("../../client/createArea");
var encoding_1 = require("../../encoding");
/**
 * Handles updated area list
 * @param {Array} args packet arguments
 */
var handleFA = function (args) {
    client_1.client.resetAreaList();
    for (var i = 1; i < args.length; i++) {
        (0, createArea_1.createArea)(i - 1, (0, encoding_1.safeTags)(args[i]));
    }
};
exports.handleFA = handleFA;
