"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleARUP = void 0;
var client_1 = require("../../client");
var encoding_1 = require("../../encoding");
/**
   * Handle the change of players in an area.
   * @param {Array} args packet arguments
   */
var handleARUP = function (args) {
    args = args.slice(1);
    for (var i = 0; i < args.length - 1; i++) {
        if (client_1.client.areas[i]) {
            // the server sends us ARUP before we even get the area list
            var thisarea = document.getElementById("area".concat(i));
            switch (Number(args[0])) {
                case 0: // playercount
                    client_1.client.areas[i].players = Number(args[i + 1]);
                    break;
                case 1: // status
                    client_1.client.areas[i].status = (0, encoding_1.safeTags)(args[i + 1]);
                    break;
                case 2:
                    client_1.client.areas[i].cm = (0, encoding_1.safeTags)(args[i + 1]);
                    break;
                case 3:
                    client_1.client.areas[i].locked = (0, encoding_1.safeTags)(args[i + 1]);
                    break;
            }
            thisarea.className = "area-button area-".concat(client_1.client.areas[i].status.toLowerCase());
            thisarea.innerText = "".concat(client_1.client.areas[i].name, " (").concat(client_1.client.areas[i].players, ") [").concat(client_1.client.areas[i].status, "]");
            thisarea.title =
                "Players: ".concat(client_1.client.areas[i].players, "\n") +
                    "Status: ".concat(client_1.client.areas[i].status, "\n") +
                    "CM: ".concat(client_1.client.areas[i].cm, "\n") +
                    "Area lock: ".concat(client_1.client.areas[i].locked);
        }
    }
};
exports.handleARUP = handleARUP;
