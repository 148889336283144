"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fix_last_area = void 0;
var client_1 = require("../client");
var addTrack_1 = require("./addTrack");
/**
 * Area list fuckery
 */
var fix_last_area = function () {
    if (client_1.client.areas.length > 0) {
        var malplaced = client_1.client.areas.pop().name;
        var areas = document.getElementById("areas");
        areas.removeChild(areas.lastChild);
        (0, addTrack_1.addTrack)(malplaced);
    }
};
exports.fix_last_area = fix_last_area;
