"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleCT = void 0;
var queryParser_1 = require("../../utils/queryParser");
var encoding_1 = require("../../encoding");
var mode = (0, queryParser_1.default)().mode;
/**
   * Handles an out-of-character chat message.
   * @param {Array} args packet arguments
   */
var handleCT = function (args) {
    if (mode !== "replay") {
        var oocLog = document.getElementById("client_ooclog");
        var username = (0, encoding_1.prepChat)(args[1]);
        var message = addLinks((0, encoding_1.prepChat)(args[2]));
        // Replace newlines with br
        message = message.replace(/\n/g, "<br>");
        oocLog.innerHTML += "".concat(username, ": ").concat(message, "<br>");
        if (oocLog.scrollTop + oocLog.offsetHeight + 120 > oocLog.scrollHeight)
            oocLog.scrollTo(0, oocLog.scrollHeight);
    }
};
exports.handleCT = handleCT;
// If the incoming message contains a link, add a href hyperlink to it
function addLinks(message) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return message.replace(urlRegex, function (url) { return "<a href=\"".concat(url, "\" target=\"_blank\">").concat(url, "</a>"); });
}
