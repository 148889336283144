"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createArea = void 0;
var client_1 = require("../client");
var areaClick_1 = require("../dom/areaClick");
var createArea = function (id, name) {
    var thisarea = {
        name: name,
        players: 0,
        status: "IDLE",
        cm: "",
        locked: "FREE",
    };
    client_1.client.areas.push(thisarea);
    // Create area button
    var newarea = document.createElement("SPAN");
    newarea.className = "area-button area-default";
    newarea.id = "area".concat(id);
    newarea.innerText = thisarea.name;
    newarea.title =
        "Players: ".concat(thisarea.players, "\n") +
            "Status: ".concat(thisarea.status, "\n") +
            "CM: ".concat(thisarea.cm, "\n") +
            "Area lock: ".concat(thisarea.locked);
    newarea.onclick = function () {
        (0, areaClick_1.area_click)(newarea);
    };
    document.getElementById("areas").appendChild(newarea);
};
exports.createArea = createArea;
