"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleEM = void 0;
var client_1 = require("../../client");
var addTrack_1 = require("../../client/addTrack");
var createArea_1 = require("../../client/createArea");
var fixLastArea_1 = require("../../client/fixLastArea");
var isAudio_1 = require("../../client/isAudio");
/**
 * Handles incoming music information, containing multiple entries
 * per packet.
 * @param {Array} args packet arguments
 */
var handleEM = function (args) {
    document.getElementById("client_loadingtext").innerHTML = "Loading Music";
    if (args[1] === "0") {
        client_1.client.resetMusicList();
        client_1.client.resetAreaList();
        client_1.client.musics_time = false;
    }
    for (var i = 2; i < args.length - 1; i++) {
        if (i % 2 === 0) {
            var trackname = args[i];
            var trackindex = Number(args[i - 1]);
            if (client_1.client.musics_time) {
                (0, addTrack_1.addTrack)(trackname);
            }
            else if ((0, isAudio_1.isAudio)(trackname)) {
                client_1.client.musics_time = true;
                (0, fixLastArea_1.fix_last_area)();
                (0, addTrack_1.addTrack)(trackname);
            }
            else {
                (0, createArea_1.createArea)(trackindex, trackname);
            }
        }
    }
    // get the next batch of tracks
    client_1.client.sender.sendServer("AM#".concat(Number(args[1]) / 10 + 1, "#%"));
};
exports.handleEM = handleEM;
