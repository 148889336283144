"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultChatMsg = void 0;
var client_1 = require("../../client");
exports.defaultChatMsg = {
    content: "",
    objection: 0,
    sound: "",
    startpreanim: true,
    startspeaking: false,
    side: null,
    color: 0,
    snddelay: 0,
    preanimdelay: 0,
    speed: client_1.UPDATE_INTERVAL,
};
