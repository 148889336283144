"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleRC = void 0;
var client_1 = require("../../client");
var characters_js_1 = require("../../constants/characters.js");
/**
 * we are asking ourselves what characters there are
 * @param {Array} args packet arguments
 */
var handleRC = function (_args) {
    client_1.client.sender.sendSelf("SC#".concat(characters_js_1.default.join("#"), "#%"));
};
exports.handleRC = handleRC;
