"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateActionCommands = updateActionCommands;
/**
 * Update evidence icon.
 */
function updateActionCommands(side) {
    if (side === "jud") {
        document.getElementById("judge_action").style.display = "inline-table";
        document.getElementById("no_action").style.display = "none";
    }
    else {
        document.getElementById("judge_action").style.display = "none";
        document.getElementById("no_action").style.display = "inline-table";
    }
    // Update role selector
    for (var i = 0, role_select = document.getElementById("role_select"); i < role_select.options.length; i++) {
        if (side === role_select.options[i].value) {
            role_select.options.selectedIndex = i;
            return;
        }
    }
}
window.updateActionCommands = updateActionCommands;
