"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onReplayGo = onReplayGo;
var client_1 = require("../client");
/**
 * Triggered when the user click replay GOOOOO
 * @param {KeyboardEvent} event
 */
function onReplayGo(_event) {
    client_1.client.handleReplay();
}
window.onReplayGo = onReplayGo;
