"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleHP = void 0;
var client_1 = require("../../client");
/**
 * Handles a change in the health bars' states.
 * @param {Array} args packet arguments
 */
var handleHP = function (args) {
    var percent_hp = Number(args[2]) * 10;
    var healthbox;
    if (args[1] === "1") {
        // Def hp
        client_1.client.hp[0] = Number(args[2]);
        healthbox = document.getElementById("client_defense_hp");
    }
    else {
        // Pro hp
        client_1.client.hp[1] = Number(args[2]);
        healthbox = document.getElementById("client_prosecutor_hp");
    }
    (healthbox.getElementsByClassName("health-bar")[0]).style.width = "".concat(percent_hp, "%");
};
exports.handleHP = handleHP;
