"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleRD = void 0;
var client_1 = require("../../client");
/**
 * we are asking ourselves what characters there are
 * @param {Array} args packet arguments
 */
var handleRD = function (_args) {
    client_1.client.sender.sendSelf("BN#gs4#%");
    client_1.client.sender.sendSelf("DONE#%");
    var ooclog = document.getElementById("client_ooclog");
    ooclog.value = "";
    ooclog.readOnly = false;
    document.getElementById("client_oocinput").style.display = "none";
    document.getElementById("client_replaycontrols").style.display =
        "inline-block";
};
exports.handleRD = handleRD;
