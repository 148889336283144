"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resizeChatbox = resizeChatbox;
var client_1 = require("../client");
/**
 * Set the font size for the chatbox
 */
function resizeChatbox() {
    var chatContainerBox = document.getElementById("client_chatcontainer");
    var clockBox = document.getElementById("client_clock");
    var trackstatusBox = document.getElementById("client_trackstatus");
    var gameHeight = document.getElementById("client_background").offsetHeight;
    chatContainerBox.style.fontSize = "".concat((gameHeight * 0.0521).toFixed(1), "px");
    clockBox.style.fontSize = "".concat((gameHeight * 0.0521).toFixed(1), "px");
    trackstatusBox.style.fontSize = "".concat((gameHeight * 0.0521).toFixed(1), "px");
    var trackstatus = (document.getElementById("client_trackstatustext"));
    trackstatus.style.width = (trackstatus.offsetWidth - 1) + "px";
    //name spacing
    var namePlate = document.getElementById("client_name");
    if (client_1.CHATBOX == "aa" && namePlate.innerText.length < 5) {
        namePlate.style.letterSpacing = "0.2em";
    }
    else {
        namePlate.style.letterSpacing = "normal";
    }
    //clock
    var now = new Date();
    var weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    document.getElementById("client_clock_month").innerText = month[now.getMonth()];
    if (client_1.CHATBOX == "acww") {
        weekday = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
        document.getElementById("client_clock_weekday").innerText = weekday[now.getDay()];
        document.getElementById("client_clock_date").innerText = now.getDay() + "/" + now.getMonth();
        document.getElementById("client_clock_time").innerText = now.getHours() + ":" + now.getMinutes();
    }
    else if (client_1.CHATBOX == "key") {
        weekday = ["Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat."];
        document.getElementById("client_clock_weekday").innerText = weekday[now.getDay()];
        document.getElementById("client_clock_date").innerText = String(now.getDate());
    }
}
window.resizeChatbox = resizeChatbox;
