"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadResources = void 0;
var getCookie_1 = require("../utils/getCookie");
var evidence_js_1 = require("../constants/evidence.js");
var backgrounds_js_1 = require("../constants/backgrounds.js");
var changeMusicVolume_1 = require("../dom/changeMusicVolume");
var setChatbox_1 = require("../dom/setChatbox");
var changeVolume_1 = require("../dom/changeVolume");
var showNameClick_1 = require("../dom/showNameClick");
var changeBlipVolume_1 = require("../dom/changeBlipVolume");
var reloadTheme_1 = require("../dom/reloadTheme");
var version = process.env.npm_package_version;
/**
   * Load game resources and stored settings.
   */
var loadResources = function () {
    document.getElementById("client_version").innerText = "version ".concat(version);
    // Load background array to select
    var background_select = (document.getElementById("bg_select"));
    background_select.add(new Option("Custom", "0"));
    backgrounds_js_1.default.forEach(function (background) {
        background_select.add(new Option(background));
    });
    // Load evidence array to select
    var evidence_select = (document.getElementById("evi_select"));
    evidence_select.add(new Option("Custom", "0"));
    evidence_js_1.default.forEach(function (evidence) {
        evidence_select.add(new Option(evidence));
    });
    // Read cookies and set the UI to its values
    document.getElementById("OOC_name").value =
        (0, getCookie_1.default)("OOC_name") ||
            "web".concat(String(Math.round(Math.random() * 100 + 10)));
    // Read cookies and set the UI to its values
    var cookietheme = (0, getCookie_1.default)("theme") || "default";
    (document.querySelector("#client_themeselect [value=\"".concat(cookietheme, "\"]"))).selected = true;
    (0, reloadTheme_1.reloadTheme)();
    var cookiechatbox = (0, getCookie_1.default)("chatbox") || "dynamic";
    (document.querySelector("#client_chatboxselect [value=\"".concat(cookiechatbox, "\"]"))).selected = true;
    (0, setChatbox_1.setChatbox)(cookiechatbox);
    document.getElementById("client_mvolume").value =
        (0, getCookie_1.default)("musicVolume") || "1";
    (0, changeMusicVolume_1.changeMusicVolume)();
    document.getElementById("client_sfxaudio").volume =
        Number((0, getCookie_1.default)("sfxVolume")) || 1;
    (0, changeVolume_1.changeSFXVolume)();
    document.getElementById("client_shoutaudio").volume =
        Number((0, getCookie_1.default)("shoutVolume")) || 1;
    (0, changeVolume_1.changeShoutVolume)();
    (document.getElementById("client_testimonyaudio")).volume = Number((0, getCookie_1.default)("testimonyVolume")) || 1;
    (0, changeVolume_1.changeTestimonyVolume)();
    document.getElementById("client_bvolume").value =
        (0, getCookie_1.default)("blipVolume") || "1";
    (0, changeBlipVolume_1.changeBlipVolume)();
    document.getElementById("ic_chat_name").value =
        (0, getCookie_1.default)("ic_chat_name");
    document.getElementById("showname").checked = Boolean((0, getCookie_1.default)("showname"));
    (0, showNameClick_1.showname_click)(null);
    document.getElementById("client_callwords").value =
        (0, getCookie_1.default)("callwords");
};
exports.loadResources = loadResources;
