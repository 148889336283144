"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleBN = void 0;
var client_1 = require("../../client");
var aoHost_1 = require("../../client/aoHost");
var encoding_1 = require("../../encoding");
var updateBackgroundPreview_1 = require("../../dom/updateBackgroundPreview");
var getIndexFromSelect_1 = require("../../dom/getIndexFromSelect");
var switchPanTilt_1 = require("../../dom/switchPanTilt");
var transparentPng_1 = require("../../constants/transparentPng");
var tryUrls_1 = require("../../utils/tryUrls");
/**
 * Handles a background change.
 * @param {Array} args packet arguments
 */
var handleBN = function (args) {
    var bgFromArgs = (0, encoding_1.safeTags)(args[1]);
    client_1.client.viewport.setBackgroundName(bgFromArgs);
    var bgfolder = client_1.client.viewport.getBackgroundFolder();
    var bg_index = (0, getIndexFromSelect_1.getIndexFromSelect)("bg_select", client_1.client.viewport.getBackgroundName());
    document.getElementById("bg_select").selectedIndex =
        bg_index;
    (0, updateBackgroundPreview_1.updateBackgroundPreview)();
    if (bg_index === 0) {
        document.getElementById("bg_filename").value =
            client_1.client.viewport.getBackgroundName();
    }
    (0, tryUrls_1.default)("".concat(aoHost_1.AO_HOST, "background/").concat(encodeURI(args[1].toLowerCase()), "/defenseempty")).then(function (resp) {
        document.getElementById("bg_preview").src = resp;
    });
    (0, tryUrls_1.default)("".concat(bgfolder, "defensedesk")).then(function (resp) {
        document.getElementById("client_def_bench").src =
            resp;
    });
    (0, tryUrls_1.default)("".concat(bgfolder, "stand")).then(function (resp) {
        document.getElementById("client_wit_bench").src =
            resp;
    });
    (0, tryUrls_1.default)("".concat(bgfolder, "prosecutiondesk")).then(function (resp) {
        document.getElementById("client_pro_bench").src =
            resp;
    });
    (0, tryUrls_1.default)("".concat(bgfolder, "court")).then(function (resp) {
        document.getElementById("client_court").src = resp;
        if (resp !== transparentPng_1.default) {
            document.getElementById("client_pantilt").checked = true;
            (0, switchPanTilt_1.switchPanTilt)();
        }
    });
    (0, tryUrls_1.default)("".concat(bgfolder, "defenseempty")).then(function (resp) {
        document.getElementById("client_court_def").src =
            resp;
    });
    (0, tryUrls_1.default)("".concat(bgfolder, "transition_def")).then(function (resp) {
        document.getElementById("client_court_deft").src =
            resp;
    });
    (0, tryUrls_1.default)("".concat(bgfolder, "witnessempty")).then(function (resp) {
        document.getElementById("client_court_wit").src =
            resp;
    });
    (0, tryUrls_1.default)("".concat(bgfolder, "transition_pro")).then(function (resp) {
        document.getElementById("client_court_prot").src =
            resp;
    });
    (0, tryUrls_1.default)("".concat(bgfolder, "prosecutorempty")).then(function (resp) {
        document.getElementById("client_court_pro").src =
            resp;
    });
    if (client_1.client.charID === -1) {
        client_1.client.viewport.set_side({
            position: "jud",
            showSpeedLines: false,
            showDesk: true,
        });
    }
    else {
        client_1.client.viewport.set_side({
            position: client_1.client.chars[client_1.client.charID].side,
            showSpeedLines: false,
            showDesk: true,
        });
    }
};
exports.handleBN = handleBN;
