"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleRT = void 0;
var client_1 = require("../../client");
var initTestimonyUpdater_1 = require("../../viewport/utils/initTestimonyUpdater");
/**
  * Handles a testimony states.
  * @param {Array} args packet arguments
  */
var handleRT = function (args) {
    var judgeid = Number(args[2]);
    switch (args[1]) {
        case "testimony1":
            client_1.client.testimonyID = 1;
            break;
        case "testimony2":
            // Cross Examination
            client_1.client.testimonyID = 2;
            break;
        case "judgeruling":
            client_1.client.testimonyID = 3 + judgeid;
            break;
        default:
            console.warn("Invalid testimony");
    }
    (0, initTestimonyUpdater_1.initTestimonyUpdater)();
};
exports.handleRT = handleRT;
