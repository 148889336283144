"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleCharacterInfo = exports.getCharIcon = void 0;
var client_1 = require("../client");
var encoding_1 = require("../encoding");
var iniParse_1 = require("../iniParse");
var request_1 = require("../services/request");
var fileExists_1 = require("../utils/fileExists");
var aoHost_1 = require("./aoHost");
var getCharIcon = function (img, charname) { return __awaiter(void 0, void 0, void 0, function () {
    var extensions, charIconBaseUrl, i, fileUrl, exists;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                extensions = [".png", ".webp"];
                img.alt = charname;
                charIconBaseUrl = "".concat(aoHost_1.AO_HOST, "characters/").concat(encodeURI(charname.toLowerCase()), "/char_icon");
                i = 0;
                _a.label = 1;
            case 1:
                if (!(i < extensions.length)) return [3 /*break*/, 4];
                fileUrl = charIconBaseUrl + extensions[i];
                return [4 /*yield*/, (0, fileExists_1.default)(fileUrl)];
            case 2:
                exists = _a.sent();
                if (exists) {
                    img.alt = charname;
                    img.title = charname;
                    img.src = fileUrl;
                    return [2 /*return*/];
                }
                _a.label = 3;
            case 3:
                i++;
                return [3 /*break*/, 1];
            case 4: return [2 /*return*/];
        }
    });
}); };
exports.getCharIcon = getCharIcon;
/**
 * Handles the incoming character information, and downloads the sprite + ini for it
 * @param {Array} chargs packet arguments
 * @param {Number} charid character ID
 */
var handleCharacterInfo = function (chargs, charid) { return __awaiter(void 0, void 0, void 0, function () {
    var img, cini, cinidata, err_1, mute_select, pair_select, default_options, default_emotions;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                img = document.getElementById("demo_".concat(charid));
                if (!chargs[0]) return [3 /*break*/, 5];
                cini = {};
                (0, exports.getCharIcon)(img, chargs[0]);
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, (0, request_1.default)("".concat(aoHost_1.AO_HOST, "characters/").concat(encodeURI(chargs[0].toLowerCase()), "/char.ini"))];
            case 2:
                cinidata = _a.sent();
                cini = (0, iniParse_1.default)(cinidata);
                return [3 /*break*/, 4];
            case 3:
                err_1 = _a.sent();
                cini = {};
                img.classList.add("noini");
                console.warn("character ".concat(chargs[0], " is missing from webAO"));
                return [3 /*break*/, 4];
            case 4:
                mute_select = (document.getElementById("mute_select"));
                mute_select.add(new Option((0, encoding_1.safeTags)(chargs[0]), String(charid)));
                pair_select = (document.getElementById("pair_select"));
                pair_select.add(new Option((0, encoding_1.safeTags)(chargs[0]), String(charid)));
                default_options = {
                    name: chargs[0],
                    showname: chargs[0],
                    side: "def",
                    blips: "male",
                    chat: "",
                    category: "",
                };
                cini.options = Object.assign(default_options, cini.options);
                default_emotions = {
                    number: 0,
                };
                cini.emotions = Object.assign(default_emotions, cini.emotions);
                client_1.client.chars[charid] = {
                    name: (0, encoding_1.safeTags)(chargs[0]),
                    showname: (0, encoding_1.safeTags)(cini.options.showname),
                    desc: (0, encoding_1.safeTags)(chargs[1]),
                    blips: (0, encoding_1.safeTags)(cini.options.blips).toLowerCase(),
                    gender: (0, encoding_1.safeTags)(cini.options.gender).toLowerCase(),
                    side: (0, encoding_1.safeTags)(cini.options.side).toLowerCase(),
                    chat: cini.options.chat === ""
                        ? (0, encoding_1.safeTags)(cini.options.category).toLowerCase()
                        : (0, encoding_1.safeTags)(cini.options.chat).toLowerCase(),
                    evidence: chargs[3],
                    icon: img.src,
                    inifile: cini,
                    muted: false,
                };
                if (client_1.client.chars[charid].blips === "male" &&
                    client_1.client.chars[charid].gender !== "male" &&
                    client_1.client.chars[charid].gender !== "") {
                    client_1.client.chars[charid].blips = client_1.client.chars[charid].gender;
                }
                return [3 /*break*/, 6];
            case 5:
                console.warn("missing charid ".concat(charid));
                img.style.display = "none";
                _a.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}); };
exports.handleCharacterInfo = handleCharacterInfo;
