"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendICLog = appendICLog;
var client_1 = require("../client");
/**
 * Appends a message to the in-character chat log.
 * @param {string} msg the string to be added
 * @param {string} name the name of the sender
 */
function appendICLog(msg, showname, nameplate, time) {
    if (showname === void 0) { showname = ""; }
    if (nameplate === void 0) { nameplate = ""; }
    if (time === void 0) { time = new Date(); }
    var entry = document.createElement("p");
    var shownameField = document.createElement("span");
    var nameplateField = document.createElement("span");
    var textField = document.createElement("span");
    nameplateField.className = "iclog_name iclog_nameplate";
    nameplateField.appendChild(document.createTextNode(nameplate));
    shownameField.className = "iclog_name iclog_showname";
    if (showname === "" || !showname) {
        shownameField.appendChild(document.createTextNode(nameplate));
    }
    else {
        shownameField.appendChild(document.createTextNode(showname));
    }
    textField.className = "iclog_text";
    textField.appendChild(document.createTextNode(msg));
    entry.appendChild(shownameField);
    entry.appendChild(nameplateField);
    entry.appendChild(textField);
    // Only put a timestamp if the minute has changed.
    if (client_1.lastICMessageTime.getMinutes() !== time.getMinutes()) {
        var timeStamp = document.createElement("span");
        timeStamp.className = "iclog_time";
        timeStamp.innerText = time.toLocaleTimeString(undefined, {
            hour: "numeric",
            minute: "2-digit",
        });
        entry.appendChild(timeStamp);
    }
    var clientLog = document.getElementById("client_log");
    clientLog.appendChild(entry);
    if (clientLog.scrollTop + clientLog.offsetHeight + 120 > clientLog.scrollHeight)
        clientLog.scrollTo(0, clientLog.scrollHeight);
    (0, client_1.setLastICMessageTime)(new Date());
}
