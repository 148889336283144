"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleSP = void 0;
var updateActionCommands_1 = require("../../dom/updateActionCommands");
/**
* position change
* @param {string} pos new position
*/
var handleSP = function (args) {
    (0, updateActionCommands_1.updateActionCommands)(args[1]);
};
exports.handleSP = handleSP;
