"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var transparentPng_1 = require("../constants/transparentPng");
var fileExists_1 = require("../utils/fileExists");
/**
 * Sets all the img tags to the right sources
 * @param {*} chatmsg
 */
var setEmote = function (AO_HOST, client, charactername, emotename, prefix, pair, side) { return __awaiter(void 0, void 0, void 0, function () {
    var pairID, characterFolder, acceptedPositions, position, emoteSelector, extensionsMap, extensionsMap_1, extensionsMap_1_1, extension, url, exists, e_1_1;
    var e_1, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                pairID = pair ? "pair" : "char";
                characterFolder = "".concat(AO_HOST, "characters/");
                acceptedPositions = ["def", "pro", "wit"];
                position = acceptedPositions.includes(side) ? "".concat(side, "_") : "";
                emoteSelector = document.getElementById("client_".concat(position).concat(pairID, "_img"));
                extensionsMap = [".gif", ".png", ".apng", ".webp", ".webp.static"];
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, 7, 8]);
                extensionsMap_1 = __values(extensionsMap), extensionsMap_1_1 = extensionsMap_1.next();
                _b.label = 2;
            case 2:
                if (!!extensionsMap_1_1.done) return [3 /*break*/, 5];
                extension = extensionsMap_1_1.value;
                // Hides all sprites before creating a new sprite
                if (client.viewport.getLastCharacter() !== client.viewport.getChatmsg().name) {
                    emoteSelector.src = transparentPng_1.default;
                }
                url = void 0;
                if (extension === ".png") {
                    url = "".concat(characterFolder).concat(encodeURI(charactername), "/").concat(encodeURI(emotename)).concat(extension);
                }
                else if (extension === ".webp.static") {
                    url = "".concat(characterFolder).concat(encodeURI(charactername), "/").concat(encodeURI(emotename), ".webp");
                }
                else {
                    url = "".concat(characterFolder).concat(encodeURI(charactername), "/").concat(encodeURI(prefix)).concat(encodeURI(emotename)).concat(extension);
                }
                return [4 /*yield*/, (0, fileExists_1.default)(url)];
            case 3:
                exists = _b.sent();
                if (exists) {
                    emoteSelector.src = url;
                    return [3 /*break*/, 5];
                }
                _b.label = 4;
            case 4:
                extensionsMap_1_1 = extensionsMap_1.next();
                return [3 /*break*/, 2];
            case 5: return [3 /*break*/, 8];
            case 6:
                e_1_1 = _b.sent();
                e_1 = { error: e_1_1 };
                return [3 /*break*/, 8];
            case 7:
                try {
                    if (extensionsMap_1_1 && !extensionsMap_1_1.done && (_a = extensionsMap_1.return)) _a.call(extensionsMap_1);
                }
                finally { if (e_1) throw e_1.error; }
                return [7 /*endfinally*/];
            case 8: return [2 /*return*/];
        }
    });
}); };
exports.default = setEmote;
