"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendCheck = void 0;
var client_1 = require("../../client");
/**
 * Sends a keepalive packet.
 */
var sendCheck = function () {
    client_1.client.sender.sendServer("CH#".concat(client_1.client.charID, "#%"));
};
exports.sendCheck = sendCheck;
