"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBlipsChannels = void 0;
var opusCheck_1 = require("../../dom/opusCheck");
var createBlipsChannels = function () {
    var blipSelectors = document.getElementsByClassName("blipSound");
    var blipChannels = __spreadArray([], __read(blipSelectors), false);
    // Allocate multiple blip audio channels to make blips less jittery
    blipChannels.forEach(function (channel) { return (channel.volume = 0.5); });
    blipChannels.forEach(function (channel) { return (channel.onerror = (0, opusCheck_1.opusCheck)(channel)); });
    return blipChannels;
};
exports.createBlipsChannels = createBlipsChannels;
