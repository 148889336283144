"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var downloadFile = function (content, filename) {
    var a = document.createElement('a');
    var file = new Blob([content], { type: 'text' });
    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();
};
exports.default = downloadFile;
