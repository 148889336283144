"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleBB = void 0;
var encoding_1 = require("../../encoding");
/**
 * Handles the warning packet
 * on client this spawns a message box you can't close for 2 seconds
 * @param {Array} args ban reason
 */
var handleBB = function (args) {
    alert((0, encoding_1.safeTags)(args[1]));
};
exports.handleBB = handleBB;
