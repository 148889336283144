"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleBD = void 0;
var client_1 = require("../../client");
var encoding_1 = require("../../encoding");
var handleBans_1 = require("../../client/handleBans");
/**
 * Handles the banned packet
 * this one is sent when you try to reconnect but you're banned
 * @param {Array} args ban reason
 */
var handleBD = function (args) {
    (0, handleBans_1.handleBans)("Banned", (0, encoding_1.safeTags)(args[1]));
    (0, client_1.setBanned)(true);
};
exports.handleBD = handleBD;
