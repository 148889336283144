"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleaskchaa = void 0;
var client_1 = require("../../client");
var characters_js_1 = require("../../constants/characters.js");
/**
   * What? you want a character list from me??
   * @param {Array} args packet arguments
   */
var handleaskchaa = function (_args) {
    client_1.client.sender.sendSelf("SI#".concat(characters_js_1.default.length, "#0#0#%"));
};
exports.handleaskchaa = handleaskchaa;
