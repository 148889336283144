export default [
    'Announce The Truth (AA).opus',
    'Announce The Truth (AJ).opus',
    'Announce The Truth (JFA).opus',
    'Announce The Truth (Miles).opus',
    'Announce The Truth (T&T).opus',
    'Confrontation ~ Presto 2009.opus',
    'Crises of Fate.opus',
    'Forgotten Legend.opus',
    'Godot - The Fragrance of Dark Coffee.opus',
    'Great Revival ~ Franziska von Karma.opus',
    'Great Revival ~ Miles Edgeworth.opus',
    'Hotline of Fate.opus',
    'Interesting People.opus',
    'Logic and Trick.opus',
    'Luke Atmey ~ I Just Want Love.opus',
    'Noisy People.opus',
    'OBJECTION (AA).opus',
    'Objection (AJ).opus',
    'OBJECTION (JFA).opus',
    'Objection (Miles).opus',
    'OBJECTION (T&T).opus',
    'Others ~ Guilty love.opus',
    'Prelude (AA).opus',
    'Prelude (AJ).opus',
    'Prologue (AA).opus',
    'Pursuit (AA) - variation.opus',
    'Pursuit (AA).opus',
    'Pursuit (AJ).opus',
    'Pursuit (DS).opus',
    'Pursuit (JFA) - variation.opus',
    'Pursuit (JFA).opus',
    'Pursuit (Miles).opus',
    'Pursuit (T&T) - variation.opus',
    'Pursuit (T&T).opus',
    'Pursuit ~ I Want to Find the Truth (Orchestra).opus',
    'Questioning AA (Allegro).opus',
    'Questioning AA (Moderato).opus',
    'Questioning AJ (Allegro).opus',
    'Questioning AJ (Moderato).opus',
    'Questioning JFA (Allegro).opus',
    'Questioning JFA (Moderato).opus',
    'Questioning T&T (Allegro).opus',
    'Questioning T&T (Moderato).opus',
    'Speak up Pup.opus',
    'Suspense (AA).opus',
    'The Great Truth Burglar.opus',
    'Trial (AA).opus',
    'Trial (AJ).opus',
    'Trial (Miles).opus',
];
