"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendOOC = void 0;
var client_1 = require("../../client");
var encoding_1 = require("../../encoding");
var setCookie_1 = require("../../utils/setCookie");
var saveChatLogHandle_1 = require("../../client/saveChatLogHandle");
/**
 * Sends an out-of-character chat message.
 * @param {string} message the message to send
 */
var sendOOC = function (message) {
    (0, setCookie_1.default)("OOC_name", document.getElementById("OOC_name").value);
    var oocName = "".concat((0, encoding_1.escapeChat)(document.getElementById("OOC_name").value));
    var oocMessage = "".concat((0, encoding_1.escapeChat)(message));
    var commands = {
        "/save_chatlog": saveChatLogHandle_1.saveChatlogHandle,
    };
    var commandsMap = new Map(Object.entries(commands));
    if (oocMessage && commandsMap.has(oocMessage.toLowerCase())) {
        try {
            commandsMap.get(oocMessage.toLowerCase())();
        }
        catch (e) {
            // Command Not Recognized
        }
    }
    else {
        client_1.client.sender.sendServer("CT#".concat(oocName, "#").concat(oocMessage, "#%"));
    }
};
exports.sendOOC = sendOOC;
