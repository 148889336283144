"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleCI = void 0;
var client_1 = require("../../client");
var handleCharacterInfo_1 = require("../../client/handleCharacterInfo");
/**
   * Handles incoming character information, bundling multiple characters
   * per packet.
   * CI#0#Phoenix&description&&&&&#1#Miles ...
   * @param {Array} args packet arguments
   */
var handleCI = function (args) {
    // Loop through the 10 characters that were sent
    document.getElementById("client_loadingtext").innerHTML = "Loading Character ".concat(args[1], "/").concat(client_1.client.char_list_length);
    var _loop_1 = function (i) {
        if (i % 2 === 0) {
            var chargs_1 = args[i].split("&");
            var charid_1 = Number(args[i - 1]);
            setTimeout(function () { return (0, handleCharacterInfo_1.handleCharacterInfo)(chargs_1, charid_1); }, 500);
        }
    };
    for (var i = 2; i <= args.length - 2; i++) {
        _loop_1(i);
    }
    // Request the next pack
    client_1.client.sender.sendServer("AN#".concat(Number(args[1]) / 10 + 1, "#%"));
};
exports.handleCI = handleCI;
