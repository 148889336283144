"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handlePU = void 0;
var client_1 = require("../../client");
var handleCharacterInfo_1 = require("../../client/handleCharacterInfo");
var updatePlayerAreas_1 = require("../../dom/updatePlayerAreas");
/**
  * Handles a playerlist update
  * @param {Array} args packet arguments
  */
var handlePU = function (args) {
    var playerRow = document.getElementById("client_playerlist_entry".concat(Number(args[1])));
    var type = Number(args[2]);
    var data = args[3];
    switch (type) {
        case 0:
            var oocName = playerRow.childNodes[3];
            oocName.innerText = data;
            break;
        case 1:
            var playerImg = playerRow.childNodes[0].firstChild;
            (0, handleCharacterInfo_1.getCharIcon)(playerImg, data);
            var charName = playerRow.childNodes[1];
            charName.innerText = data;
            break;
        case 2:
            var showName = playerRow.childNodes[2];
            showName.innerText = data;
            break;
        case 3:
            playerRow.className = "area".concat(data);
            (0, updatePlayerAreas_1.updatePlayerAreas)(client_1.client.area);
        default:
            break;
    }
};
exports.handlePU = handlePU;
