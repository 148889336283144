"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.packets = void 0;
var handleMS_1 = require("./handlers/handleMS");
var handleCT_1 = require("./handlers/handleCT");
var handleMC_1 = require("./handlers/handleMC");
var handleRMC_1 = require("./handlers/handleRMC");
var handleFL_1 = require("./handlers/handleFL");
var handleLE_1 = require("./handlers/handleLE");
var handleEM_1 = require("./handlers/handleEM");
var handleEI_1 = require("./handlers/handleEI");
var handleSC_1 = require("./handlers/handleSC");
var handleCI_1 = require("./handlers/handleCI");
var handleFM_1 = require("./handlers/handleFM");
var handleFA_1 = require("./handlers/handleFA");
var handleSM_1 = require("./handlers/handleSM");
var handleMM_1 = require("./handlers/handleMM");
var handleBD_1 = require("./handlers/handleBD");
var handleBB_1 = require("./handlers/handleBB");
var handleKB_1 = require("./handlers/handleKB");
var handleKK_1 = require("./handlers/handleKK");
var handleDONE_1 = require("./handlers/handleDONE");
var handleBN_1 = require("./handlers/handleBN");
var handleHP_1 = require("./handlers/handleHP");
var handleRT_1 = require("./handlers/handleRT");
var handleTI_1 = require("./handlers/handleTI");
var handleZZ_1 = require("./handlers/handleZZ");
var handleHI_1 = require("./handlers/handleHI");
var handleID_1 = require("./handlers/handleID");
var handlePN_1 = require("./handlers/handlePN");
var handleSI_1 = require("./handlers/handleSI");
var handleARUP_1 = require("./handlers/handleARUP");
var handleAUTH_1 = require("./handlers/handleAUTH");
var handleaskchaa_1 = require("./handlers/handleaskchaa");
var handleCC_1 = require("./handlers/handleCC");
var handleRC_1 = require("./handlers/handleRC");
var handleRM_1 = require("./handlers/handleRM");
var handleRD_1 = require("./handlers/handleRD");
var handleCharsCheck_1 = require("./handlers/handleCharsCheck");
var handlePV_1 = require("./handlers/handlePV");
var handleASS_1 = require("./handlers/handleASS");
var handleackMS_1 = require("./handlers/handleackMS");
var handleSP_1 = require("./handlers/handleSP");
var handleJD_1 = require("./handlers/handleJD");
var handlePU_1 = require("./handlers/handlePU");
var handlePR_1 = require("./handlers/handlePR");
exports.packets = {
    "MS": handleMS_1.handleMS,
    "CT": handleCT_1.handleCT,
    "MC": handleMC_1.handleMC,
    "RMC": handleRMC_1.handleRMC,
    "CI": handleCI_1.handleCI,
    "SC": handleSC_1.handleSC,
    "EI": handleEI_1.handleEI,
    "FL": handleFL_1.handleFL,
    "LE": handleLE_1.handleLE,
    "EM": handleEM_1.handleEM,
    "FM": handleFM_1.handleFM,
    "FA": handleFA_1.handleFA,
    "SM": handleSM_1.handleSM,
    "MM": handleMM_1.handleMM,
    "BD": handleBD_1.handleBD,
    "BB": handleBB_1.handleBB,
    "KB": handleKB_1.handleKB,
    "KK": handleKK_1.handleKK,
    "DONE": handleDONE_1.handleDONE,
    "BN": handleBN_1.handleBN,
    "HP": handleHP_1.handleHP,
    "RT": handleRT_1.handleRT,
    "TI": handleTI_1.handleTI,
    "ZZ": handleZZ_1.handleZZ,
    "HI": handleHI_1.handleHI,
    "ID": handleID_1.handleID,
    "PN": handlePN_1.handlePN,
    "SI": handleSI_1.handleSI,
    "ARUP": handleARUP_1.handleARUP,
    "AUTH": handleAUTH_1.handleAUTH,
    "askchaa": handleaskchaa_1.handleaskchaa,
    "CC": handleCC_1.handleCC,
    "RC": handleRC_1.handleRC,
    "RM": handleRM_1.handleRM,
    "RD": handleRD_1.handleRD,
    "CharsCheck": handleCharsCheck_1.handleCharsCheck,
    "PV": handlePV_1.handlePV,
    "ASS": handleASS_1.handleASS,
    "ackMS": handleackMS_1.handleackMS,
    "SP": handleSP_1.handleSP,
    "JD": handleJD_1.handleJD,
    "PU": handlePU_1.handlePU,
    "PR": handlePR_1.handlePR,
    "decryptor": function () { },
    "CHECK": function () { },
    "CH": function () { },
};
