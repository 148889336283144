"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * read a cookie from storage
 * got this from w3schools
 * https://www.w3schools.com/js/js_cookies.asp
 * @param {string} cname The name of the cookie to return
 */
var getCookie = function (cname) {
    try {
        var name_1 = "".concat(cname, "=");
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name_1) === 0) {
                return c.substring(name_1.length, c.length);
            }
        }
        return '';
    }
    catch (error) {
        return '';
    }
};
exports.default = getCookie;
