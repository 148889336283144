export default [
    'Anime',
    'birthday',
    'Christmas',
    'CountyCourt',
    'CruiseCourt',
    'default',
    'DGSEnglishCourt',
    'DGSJapanCourt',
    'DualDestinies',
    'EnglishCourt',
    'gs4',
    'GS4Night',
    'HD',
    "Khura'in",
    'mlp',
    'NewCourt',
    'RuinedCourt',
    'Sky',
    'SpaceCourt',
    'Themis',
    'TouhouCourt',
    'WitchTrialCourt',
    'Zetta',
];
