"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleBans = void 0;
/**
 * Handles the kicked packet
 * @param {string} type is it a kick or a ban
 * @param {string} reason why
 */
var handleBans = function (type, reason) {
    document.getElementById("client_error").style.display = "flex";
    document.getElementById("client_errortext").innerHTML = "".concat(type, ":<br>").concat(reason.replace(/\n/g, "<br />"));
    (document.getElementById("client_reconnect")).style.display = "none";
    alert(type + ":\r" + reason);
};
exports.handleBans = handleBans;
