"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.set_side = void 0;
var positions_1 = require("../constants/positions");
var aoHost_1 = require("../../client/aoHost");
var client_1 = require("../../client");
var tryUrls_1 = require("../../utils/tryUrls");
var findImgSrc_1 = require("../../utils/findImgSrc");
/**
 * Changes the viewport background based on a given position.
 *
 * Valid positions: `def, pro, hld, hlp, wit, jud, jur, sea`
 * @param {string} position the position to change into
 */
var set_side = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var view, bench, court, bg, desk, speedLines, _c, bg_folder, urls_to_try, _d;
    var position = _b.position, showSpeedLines = _b.showSpeedLines, showDesk = _b.showDesk;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                view = document.getElementById("client_fullview");
                if (['def', 'pro', 'wit'].includes(position)) {
                    bench = (document.getElementById("client_".concat(position, "_bench")));
                }
                else {
                    bench = document.getElementById("client_bench_classic");
                }
                if ("def,pro,wit".includes(position)) {
                    court = (document.getElementById("client_court_".concat(position)));
                }
                else {
                    court = document.getElementById("client_court_classic");
                }
                if ("def,pro,hld,hlp,wit,jud,jur,sea".includes(position)) {
                    bg = positions_1.positions[position].bg;
                    desk = positions_1.positions[position].desk;
                    speedLines = positions_1.positions[position].speedLines;
                }
                else {
                    bg = "".concat(position);
                    desk = { ao2: "".concat(position, "_overlay.png"), ao1: "_overlay.png" };
                    speedLines = "defense_speedlines.gif";
                }
                if (!(showSpeedLines === true)) return [3 /*break*/, 1];
                court.src = "".concat(aoHost_1.AO_HOST, "themes/default/").concat(encodeURI(speedLines));
                return [3 /*break*/, 3];
            case 1:
                _c = court;
                return [4 /*yield*/, (0, tryUrls_1.default)(client_1.client.viewport.getBackgroundFolder() + bg)];
            case 2:
                _c.src = _e.sent();
                _e.label = 3;
            case 3:
                if (!(showDesk === true && desk)) return [3 /*break*/, 5];
                bg_folder = client_1.client.viewport.getBackgroundFolder();
                urls_to_try = [
                    bg_folder + desk.ao2,
                    bg_folder + desk.ao1,
                ];
                _d = bench;
                return [4 /*yield*/, (0, findImgSrc_1.default)(urls_to_try)];
            case 4:
                _d.src = _e.sent();
                bench.style.opacity = "1";
                return [3 /*break*/, 6];
            case 5:
                bench.style.opacity = "0";
                _e.label = 6;
            case 6:
                if ("def,pro,wit".includes(position)) {
                    view.style.display = "";
                    document.getElementById("client_classicview").style.display = "none";
                    switch (position) {
                        case "def":
                            view.style.left = "0";
                            break;
                        case "wit":
                            view.style.left = "-200%";
                            break;
                        case "pro":
                            view.style.left = "-400%";
                            break;
                    }
                }
                else {
                    view.style.display = "none";
                    document.getElementById("client_classicview").style.display = "";
                }
                return [2 /*return*/];
        }
    });
}); };
exports.set_side = set_side;
