"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleMC = void 0;
var encoding_1 = require("../../encoding");
var client_1 = require("../../client");
var aoHost_1 = require("../../client/aoHost");
var appendICLog_1 = require("../../client/appendICLog");
/**
 * Handles a music change to an arbitrary resource.
 * @param {Array} args packet arguments
 */
var handleMC = function (args) {
    var track = (0, encoding_1.prepChat)(args[1]);
    var charID = Number(args[2]);
    var showname = args[3] || "";
    var looping = Boolean(args[4]);
    var channel = Number(args[5]) || 0;
    // const fading = Number(args[6]) || 0; // unused in web
    var music = client_1.client.viewport.music[channel];
    var musicname;
    music.pause();
    if (track.startsWith("http")) {
        music.src = track;
    }
    else {
        music.src = "".concat(aoHost_1.AO_HOST, "sounds/music/").concat(encodeURI(track.toLowerCase()));
    }
    music.loop = looping;
    music.play();
    try {
        musicname = client_1.client.chars[charID].name;
    }
    catch (e) {
        charID = -1;
    }
    if (charID >= 0) {
        musicname = client_1.client.chars[charID].name;
        (0, appendICLog_1.appendICLog)("".concat(musicname, " changed music to ").concat(track));
    }
    else {
        (0, appendICLog_1.appendICLog)("The music was changed to ".concat(track));
    }
    document.getElementById("client_trackstatustext").innerText = track;
};
exports.handleMC = handleMC;
