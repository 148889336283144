"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleackMS = void 0;
var resetICParams_1 = require("../../client/resetICParams");
/**
* server got our message
*/
var handleackMS = function () {
    (0, resetICParams_1.resetICParams)();
};
exports.handleackMS = handleackMS;
