"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateBackgroundPreview = updateBackgroundPreview;
var aoHost_1 = require("../client/aoHost");
var tryUrls_1 = require("../utils/tryUrls");
/**
 * Update background preview.
 */
function updateBackgroundPreview() {
    var background_select = (document.getElementById("bg_select"));
    var background_filename = (document.getElementById("bg_filename"));
    var background_preview = (document.getElementById("bg_preview"));
    if (background_select.selectedIndex === 0) {
        background_filename.style.display = "initial";
    }
    else {
        background_filename.style.display = "none";
    }
    (0, tryUrls_1.default)("".concat(aoHost_1.AO_HOST, "background/").concat(encodeURI(background_select.value.toLowerCase()), "/defenseempty")).then(function (resp) {
        background_preview.src = resp;
    });
}
window.updateBackgroundPreview = updateBackgroundPreview;
