"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleMM = void 0;
/**
 * Handles the "MusicMode" packet
 * @param {Array} args packet arguments
 */
var handleMM = function (_args) {
    // It's unused nowadays, as preventing people from changing the music is now serverside
};
exports.handleMM = handleMM;
