"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleCC = void 0;
var client_1 = require("../../client");
/**
 * What? you want a character??
 * @param {Array} args packet arguments
 */
var handleCC = function (args) {
    client_1.client.sender.sendSelf("PV#1#CID#".concat(args[2], "#%"));
};
exports.handleCC = handleCC;
