"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSfxAudio = void 0;
var aoHost_1 = require("../../client/aoHost");
var createSfxAudio = function () {
    var sfxAudio = document.getElementById("client_sfxaudio");
    sfxAudio.src = "".concat(aoHost_1.AO_HOST, "sounds/general/sfx-realization.opus");
    return sfxAudio;
};
exports.createSfxAudio = createSfxAudio;
