"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendIC = void 0;
var client_1 = require("../../client");
var encoding_1 = require("../../encoding");
var client_2 = require("../../client");
var queryParser_1 = require("../../utils/queryParser");
var mode = (0, queryParser_1.default)().mode;
/**
 * Sends an in-character chat message.
 * @param {number} deskmod controls the desk
 * @param {string} speaking who is speaking
 * @param {string} name the name of the current character
 * @param {string} silent whether or not it's silent
 * @param {string} message the message to be sent
 * @param {string} side the name of the side in the background
 * @param {string} sfx_name the name of the sound effect
 * @param {number} emote_modifier whether or not to zoom
 * @param {number} sfx_delay the delay (in milliseconds) to play the sound effect
 * @param {number} objection_modifier the number of the shout to play
 * @param {string} evidence the filename of evidence to show
 * @param {boolean} flip change to 1 to reverse sprite for position changes
 * @param {boolean} realization screen flash effect
 * @param {number} text_color text color
 * @param {string} showname custom name to be displayed (optional)
 * @param {number} other_charid paired character (optional)
 * @param {number} self_offset offset to paired character (optional)
 * @param {number} noninterrupting_preanim play the full preanim (optional)
 */
var sendIC = function (deskmod, preanim, name, emote, message, side, sfx_name, emote_modifier, sfx_delay, objection_modifier, evidence, flip, realization, text_color, showname, other_charid, self_hoffset, self_yoffset, noninterrupting_preanim, looping_sfx, screenshake, frame_screenshake, frame_realization, frame_sfx, additive, effect) {
    var extra_cccc = "";
    var other_emote = "";
    var other_offset = "";
    var extra_27 = "";
    var extra_28 = "";
    if (client_1.extrafeatures.includes("cccc_ic_support")) {
        var self_offset = client_1.extrafeatures.includes("y_offset")
            ? "".concat(self_hoffset, "<and>").concat(self_yoffset)
            : self_hoffset; // HACK: this should be an & but client fucked it up and all the servers adopted it
        if (mode === "replay") {
            other_emote = "##";
            other_offset = "#0#0";
        }
        extra_cccc = "".concat((0, encoding_1.escapeChat)(showname), "#").concat(other_charid).concat(other_emote, "#").concat(self_offset).concat(other_offset, "#").concat(Number(noninterrupting_preanim), "#");
        if (client_1.extrafeatures.includes("looping_sfx")) {
            extra_27 = "".concat(Number(looping_sfx), "#").concat(Number(screenshake), "#").concat(frame_screenshake, "#").concat(frame_realization, "#").concat(frame_sfx, "#");
            if (client_1.extrafeatures.includes("effects")) {
                extra_28 = "".concat(Number(additive), "#").concat((0, encoding_1.escapeChat)(effect), "#");
            }
        }
    }
    var serverMessage = "MS#".concat(deskmod, "#").concat((0, encoding_1.escapeChat)(preanim), "#").concat((0, encoding_1.escapeChat)(name), "#").concat((0, encoding_1.escapeChat)(emote)) +
        "#".concat((0, encoding_1.escapeChat)(message), "#").concat((0, encoding_1.escapeChat)(side), "#").concat((0, encoding_1.escapeChat)(sfx_name), "#").concat(emote_modifier) +
        "#".concat(client_2.client.charID, "#").concat(sfx_delay, "#").concat(Number(objection_modifier), "#").concat(Number(evidence), "#").concat(Number(flip), "#").concat(Number(realization), "#").concat(text_color, "#").concat(extra_cccc).concat(extra_27).concat(extra_28, "%");
    client_2.client.sender.sendServer(serverMessage);
    if (mode === "replay") {
        (document.getElementById("client_ooclog")).value += "wait#".concat(document.getElementById("client_replaytimer").value, "#%\r\n");
    }
};
exports.sendIC = sendIC;
