"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleID = void 0;
var client_1 = require("../../client");
var version = process.env.npm_package_version;
/**
   * Identifies the server and issues a playerID
   * @param {Array} args packet arguments
   */
var handleID = function (args) {
    client_1.client.playerID = Number(args[1]);
    var serverSoftware = args[2].split("&")[0];
    var serverVersion;
    if (serverSoftware === "serverD") {
        serverVersion = args[2].split("&")[1];
    }
    else if (serverSoftware === "webAO") {
        (0, client_1.setOldLoading)(false);
        client_1.client.sender.sendSelf("PN#0#1#%");
    }
    else {
        serverVersion = args[3];
    }
    if (serverSoftware === "serverD" && serverVersion === "1377.152") {
        (0, client_1.setOldLoading)(true);
    } // bugged version
    if (serverSoftware !== "webAO") {
        client_1.client.sender.sendServer("ID#webAO#".concat(version, "#%"));
    }
};
exports.handleID = handleID;
