"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.positions = void 0;
exports.positions = {
    def: {
        bg: "defenseempty",
        desk: { ao2: "defensedesk.png", ao1: "bancodefensa.png" },
        speedLines: "defense_speedlines.gif",
    },
    pro: {
        bg: "prosecutorempty",
        desk: { ao2: "prosecutiondesk.png", ao1: "bancoacusacion.png" },
        speedLines: "prosecution_speedlines.gif",
    },
    hld: {
        bg: "helperstand",
        desk: {},
        speedLines: "defense_speedlines.gif",
    },
    hlp: {
        bg: "prohelperstand",
        desk: {},
        speedLines: "prosecution_speedlines.gif",
    },
    wit: {
        bg: "witnessempty",
        desk: { ao2: "stand.png", ao1: "estrado.png" },
        speedLines: "prosecution_speedlines.gif",
    },
    jud: {
        bg: "judgestand",
        desk: { ao2: "judgedesk.png", ao1: "judgedesk.gif" },
        speedLines: "prosecution_speedlines.gif",
    },
    jur: {
        bg: "jurystand",
        desk: { ao2: "jurydesk.png", ao1: "estrado.png" },
        speedLines: "defense_speedlines.gif",
    },
    sea: {
        bg: "seancestand",
        desk: { ao2: "seancedesk.png", ao1: "estrado.png" },
        speedLines: "prosecution_speedlines.gif",
    },
};
