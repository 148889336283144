"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleKB = void 0;
var client_1 = require("../../client");
var encoding_1 = require("../../encoding");
var handleBans_1 = require("../../client/handleBans");
/**
 * Handles the banned packet
 * this one is sent when you are kicked off the server
 * @param {Array} args ban reason
 */
var handleKB = function (args) {
    (0, handleBans_1.handleBans)("Banned", (0, encoding_1.safeTags)(args[1]));
    (0, client_1.setBanned)(true);
};
exports.handleKB = handleKB;
