"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handlePN = void 0;
var client_1 = require("../../client");
/**
 * Indicates how many users are on this server
 * @param {Array} args packet arguments
 */
var handlePN = function (_args) {
    client_1.client.sender.sendServer("askchaa#%");
};
exports.handlePN = handlePN;
