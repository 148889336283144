"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendCharacter = void 0;
var client_1 = require("../../client");
/**
 * Requests to play as a specified character.
 * @param {number} character the character ID
 */
var sendCharacter = function (character) {
    if (character === -1 || client_1.client.chars[character].name) {
        client_1.client.sender.sendServer("CC#".concat(client_1.client.playerID, "#").concat(character, "#web#%"));
    }
};
exports.sendCharacter = sendCharacter;
