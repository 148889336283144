"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLowMemory = void 0;
var client_1 = require("../client");
var isLowMemory = function () {
    if (/webOS|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|PlayStation|Nintendo|Opera Mini/i.test(navigator.userAgent)) {
        (0, client_1.setOldLoading)(true);
    }
};
exports.isLowMemory = isLowMemory;
