"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeMusicVolume = void 0;
var client_1 = require("../client");
var setCookie_1 = require("../utils/setCookie");
var changeMusicVolume = function (volume) {
    if (volume === void 0) { volume = -1; }
    var clientVolume = Number(document.getElementById("client_mvolume").value);
    var musicVolume = volume === -1 ? clientVolume : volume;
    client_1.client.viewport.music.forEach(function (channel) { return (channel.volume = musicVolume); });
    (0, setCookie_1.default)("musicVolume", String(musicVolume));
};
exports.changeMusicVolume = changeMusicVolume;
window.changeMusicVolume = exports.changeMusicVolume;
