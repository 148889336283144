"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleSI = void 0;
var client_1 = require("../../client");
/**
   * Received when the server announces its server info,
   * but we use it as a cue to begin retrieving characters.
   * @param {Array} args packet arguments
   */
var handleSI = function (args) {
    client_1.client.char_list_length = Number(args[1]);
    client_1.client.evidence_list_length = Number(args[2]);
    client_1.client.music_list_length = Number(args[3]);
    // create the charselect grid, to be filled by the character loader
    document.getElementById("client_chartable").innerHTML = "";
    for (var i = 0; i < client_1.client.char_list_length; i++) {
        var demothing = document.createElement("img");
        demothing.className = "demothing";
        demothing.id = "demo_".concat(i);
        var demoonclick = document.createAttribute("onclick");
        demoonclick.value = "pickChar(".concat(i, ")");
        demothing.setAttributeNode(demoonclick);
        document.getElementById("client_chartable").appendChild(demothing);
    }
    // this is determined at the top of this file
    if (!client_1.oldLoading) {
        client_1.client.sender.sendServer("RC#%");
    }
    else {
        client_1.client.sender.sendServer("askchar2#%");
    }
};
exports.handleSI = handleSI;
