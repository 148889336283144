"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.handlePV = void 0;
var client_1 = require("../../client");
var fileExists_1 = require("../../utils/fileExists");
var updateActionCommands_1 = require("../../dom/updateActionCommands");
var pickEmotion_1 = require("../../dom/pickEmotion");
var aoHost_1 = require("../../client/aoHost");
function addEmoteButton(i, imgurl, desc) {
    var emotesList = document.getElementById("client_emo");
    var emote_item = new Image();
    emote_item.id = "emo_" + i;
    emote_item.className = "emote_button";
    emote_item.src = imgurl;
    emote_item.alt = desc;
    emote_item.title = desc;
    emote_item.onclick = function () { window.pickEmotion(i); };
    emotesList.appendChild(emote_item);
}
/**
 * Handles the server's assignment of a character for the player to use.
 * PV # playerID (unused) # CID # character ID
 * @param {Array} args packet arguments
 */
var handlePV = function (args) { return __awaiter(void 0, void 0, void 0, function () {
    var me, emotes, emotesList, ini, i, emoteinfo, esfx, esfxd, extensionsMap, url, extensionsMap_1, extensionsMap_1_1, extension, exists, e_1_1, e_2;
    var e_1, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                client_1.client.charID = Number(args[3]);
                document.getElementById("client_waiting").style.display = "none";
                document.getElementById("client_charselect").style.display = "none";
                me = client_1.client.chars[client_1.client.charID];
                client_1.client.selectedEmote = -1;
                emotes = client_1.client.emotes;
                emotesList = document.getElementById("client_emo");
                emotesList.style.display = "";
                emotesList.innerHTML = ""; // Clear emote box
                ini = me.inifile;
                me.side = ini.options.side;
                (0, updateActionCommands_1.updateActionCommands)(me.side);
                if (!(ini.emotions.number === 0)) return [3 /*break*/, 1];
                emotesList.innerHTML = "<span\n\t\t\t\t\tid=\"emo_0\"\n\t\t\t\t\talt=\"unavailable\"\n\t\t\t\t\tclass=\"emote_button\">No emotes available</span>";
                return [3 /*break*/, 14];
            case 1:
                i = 1;
                _b.label = 2;
            case 2:
                if (!(i <= ini.emotions.number)) return [3 /*break*/, 14];
                _b.label = 3;
            case 3:
                _b.trys.push([3, 12, , 13]);
                emoteinfo = ini.emotions[i].split("#");
                esfx = void 0;
                esfxd = void 0;
                try {
                    esfx = ini.soundn[i] || "0";
                    esfxd = Number(ini.soundt[i]) || 0;
                }
                catch (e) {
                    console.warn("ini sound is completly missing");
                    esfx = "0";
                    esfxd = 0;
                }
                extensionsMap = [".png", ".webp"];
                url = void 0;
                _b.label = 4;
            case 4:
                _b.trys.push([4, 9, 10, 11]);
                extensionsMap_1 = (e_1 = void 0, __values(extensionsMap)), extensionsMap_1_1 = extensionsMap_1.next();
                _b.label = 5;
            case 5:
                if (!!extensionsMap_1_1.done) return [3 /*break*/, 8];
                extension = extensionsMap_1_1.value;
                url = "".concat(aoHost_1.AO_HOST, "characters/").concat(encodeURI(me.name.toLowerCase()), "/emotions/button").concat(i, "_off").concat(extension);
                return [4 /*yield*/, (0, fileExists_1.default)(url)];
            case 6:
                exists = _b.sent();
                if (exists) {
                    return [3 /*break*/, 8];
                }
                _b.label = 7;
            case 7:
                extensionsMap_1_1 = extensionsMap_1.next();
                return [3 /*break*/, 5];
            case 8: return [3 /*break*/, 11];
            case 9:
                e_1_1 = _b.sent();
                e_1 = { error: e_1_1 };
                return [3 /*break*/, 11];
            case 10:
                try {
                    if (extensionsMap_1_1 && !extensionsMap_1_1.done && (_a = extensionsMap_1.return)) _a.call(extensionsMap_1);
                }
                finally { if (e_1) throw e_1.error; }
                return [7 /*endfinally*/];
            case 11:
                emotes[i] = {
                    desc: emoteinfo[0].toLowerCase(),
                    preanim: emoteinfo[1].toLowerCase(),
                    emote: emoteinfo[2].toLowerCase(),
                    zoom: Number(emoteinfo[3]) || 0,
                    deskmod: Number(emoteinfo[4]) || 1,
                    sfx: esfx.toLowerCase(),
                    sfxdelay: esfxd,
                    frame_screenshake: "",
                    frame_realization: "",
                    frame_sfx: "",
                    button: url,
                };
                addEmoteButton(i, url, emotes[i].desc);
                if (i === 1)
                    (0, pickEmotion_1.pickEmotion)(1);
                return [3 /*break*/, 13];
            case 12:
                e_2 = _b.sent();
                console.error("missing emote ".concat(i));
                return [3 /*break*/, 13];
            case 13:
                i++;
                return [3 /*break*/, 2];
            case 14: return [4 /*yield*/, (0, fileExists_1.default)("".concat(aoHost_1.AO_HOST, "characters/").concat(encodeURI(me.name.toLowerCase()), "/custom.gif"))];
            case 15:
                if (_b.sent()) {
                    document.getElementById("button_4").style.display = "";
                }
                else {
                    document.getElementById("button_4").style.display = "none";
                }
                return [2 /*return*/];
        }
    });
}); };
exports.handlePV = handlePV;
