"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var request_1 = require("../services/request");
var aomlParser = function (text) {
    var e_1, _a;
    var parsed = {};
    var currentHeader = '';
    try {
        for (var _b = __values(text.split(/\r?\n/)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var line = _c.value;
            if (line === '') {
                currentHeader = '';
                continue;
            }
            var content = line.split(' = ');
            var contentName = content[0];
            var contentValue = content[1];
            if (currentHeader === '') {
                currentHeader = contentName;
                parsed[currentHeader] = {
                    color: contentValue
                };
            }
            else {
                var contentKey = contentName.split('_')[1];
                parsed[currentHeader][contentKey] = contentValue;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return parsed;
};
var mlConfig = function (AO_HOST) {
    var defaultUrl = "".concat(AO_HOST, "themes/default/chat_config.ini");
    var aomlParsed = (0, request_1.default)(defaultUrl).then(function (data) { return aomlParser(data); });
    var createIdentifiers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var identifiers, _a, _b, _c, _d, _e, ruleName, value, e_2_1;
        var e_2, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    identifiers = new Map();
                    _g.label = 1;
                case 1:
                    _g.trys.push([1, 6, 7, 8]);
                    _d = (_c = Object).entries;
                    return [4 /*yield*/, aomlParsed];
                case 2:
                    _a = __values.apply(void 0, [_d.apply(_c, [_g.sent()])]), _b = _a.next();
                    _g.label = 3;
                case 3:
                    if (!!_b.done) return [3 /*break*/, 5];
                    _e = __read(_b.value, 2), ruleName = _e[0], value = _e[1];
                    if (value.start && value.end) {
                        identifiers.set(value.start, value);
                        identifiers.set(value.end, value);
                    }
                    _g.label = 4;
                case 4:
                    _b = _a.next();
                    return [3 /*break*/, 3];
                case 5: return [3 /*break*/, 8];
                case 6:
                    e_2_1 = _g.sent();
                    e_2 = { error: e_2_1 };
                    return [3 /*break*/, 8];
                case 7:
                    try {
                        if (_b && !_b.done && (_f = _a.return)) _f.call(_a);
                    }
                    finally { if (e_2) throw e_2.error; }
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/, identifiers];
            }
        });
    }); };
    var createStartIdentifiers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var startingIdentifiers, _a, _b, _c, _d, _e, ruleName, value, e_3_1;
        var e_3, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    startingIdentifiers = new Set();
                    _g.label = 1;
                case 1:
                    _g.trys.push([1, 6, 7, 8]);
                    _d = (_c = Object).entries;
                    return [4 /*yield*/, aomlParsed];
                case 2:
                    _a = __values.apply(void 0, [_d.apply(_c, [_g.sent()])]), _b = _a.next();
                    _g.label = 3;
                case 3:
                    if (!!_b.done) return [3 /*break*/, 5];
                    _e = __read(_b.value, 2), ruleName = _e[0], value = _e[1];
                    if ((value === null || value === void 0 ? void 0 : value.start) && (value === null || value === void 0 ? void 0 : value.end)) {
                        startingIdentifiers.add(value.start);
                    }
                    _g.label = 4;
                case 4:
                    _b = _a.next();
                    return [3 /*break*/, 3];
                case 5: return [3 /*break*/, 8];
                case 6:
                    e_3_1 = _g.sent();
                    e_3 = { error: e_3_1 };
                    return [3 /*break*/, 8];
                case 7:
                    try {
                        if (_b && !_b.done && (_f = _a.return)) _f.call(_a);
                    }
                    finally { if (e_3) throw e_3.error; }
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/, startingIdentifiers];
            }
        });
    }); };
    var applyMarkdown = function (text, defaultColor) { return __awaiter(void 0, void 0, void 0, function () {
        var identifiers, startIdentifiers, closingStack, colorStack, output, text_1, text_1_1, letter, currentSelector, currentIdentifier, currentClosingLetter, keepChar, r, g, b, currentColor, color, r, g, b, currentColor, r, g, b, currentColor;
        var e_4, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, createIdentifiers()];
                case 1:
                    identifiers = _b.sent();
                    return [4 /*yield*/, createStartIdentifiers()];
                case 2:
                    startIdentifiers = _b.sent();
                    closingStack = [];
                    colorStack = [];
                    output = [];
                    try {
                        for (text_1 = __values(text), text_1_1 = text_1.next(); !text_1_1.done; text_1_1 = text_1.next()) {
                            letter = text_1_1.value;
                            currentSelector = document.createElement('span');
                            currentIdentifier = identifiers.get(letter);
                            currentClosingLetter = closingStack[closingStack.length - 1];
                            keepChar = Number(currentIdentifier === null || currentIdentifier === void 0 ? void 0 : currentIdentifier.remove) === 0;
                            if (currentClosingLetter === letter) {
                                r = colorStack[colorStack.length - 1][0];
                                g = colorStack[colorStack.length - 1][1];
                                b = colorStack[colorStack.length - 1][2];
                                currentColor = "color: rgb(".concat(r, ",").concat(g, ",").concat(b, ");");
                                currentSelector.setAttribute('style', currentColor);
                                closingStack.pop();
                                colorStack.pop();
                                if (keepChar) {
                                    currentSelector.innerHTML = letter;
                                }
                            }
                            else if (startIdentifiers.has(letter)) {
                                color = identifiers.get(letter).color.split(',');
                                r = color[0];
                                g = color[1];
                                b = color[2];
                                colorStack.push([r, g, b]);
                                closingStack.push(currentIdentifier.end);
                                currentColor = "color: rgb(".concat(r, ",").concat(g, ",").concat(b, ");");
                                currentSelector.setAttribute('style', currentColor);
                                if (keepChar) {
                                    currentSelector.innerHTML = letter;
                                }
                            }
                            else {
                                currentSelector.innerHTML = letter;
                                if (colorStack.length === 0) {
                                    currentSelector.className = "text_".concat(defaultColor);
                                }
                                else {
                                    r = colorStack[colorStack.length - 1][0];
                                    g = colorStack[colorStack.length - 1][1];
                                    b = colorStack[colorStack.length - 1][2];
                                    currentColor = "color: rgb(".concat(r, ",").concat(g, ",").concat(b, ");");
                                    currentSelector.setAttribute('style', currentColor);
                                }
                            }
                            output.push(currentSelector);
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (text_1_1 && !text_1_1.done && (_a = text_1.return)) _a.call(text_1);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                    return [2 /*return*/, output];
            }
        });
    }); };
    return {
        applyMarkdown: applyMarkdown
    };
};
exports.default = mlConfig;
