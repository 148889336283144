"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleCharsCheck = void 0;
var client_1 = require("../../client");
/**
 * Handles the list of all used and vacant characters.
 * @param {Array} args list of all characters represented as a 0 for free or a -1 for taken
 */
var handleCharsCheck = function (args) {
    for (var i = 0; i < client_1.client.char_list_length; i++) {
        var img = document.getElementById("demo_".concat(i));
        if (args[i + 1] === "-1") {
            img.style.opacity = "0.25";
        }
        else if (args[i + 1] === "0") {
            img.style.opacity = "1";
        }
    }
};
exports.handleCharsCheck = handleCharsCheck;
