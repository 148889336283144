"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTestimonyAudio = void 0;
var aoHost_1 = require("../../client/aoHost");
var createTestimonyAudio = function () {
    var testimonyAudio = document.getElementById("client_testimonyaudio");
    testimonyAudio.src = "".concat(aoHost_1.AO_HOST, "sounds/general/sfx-guilty.opus");
    return testimonyAudio;
};
exports.createTestimonyAudio = createTestimonyAudio;
