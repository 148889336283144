"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePlayerAreas = updatePlayerAreas;
var client_1 = require("../client");
/**
 * Triggered when someone switches areas
 * @param {Number} ownarea
 */
function updatePlayerAreas(ownarea) {
    var e_1, _a, e_2, _b;
    for (var i = 0; i < client_1.client.areas.length; i++) {
        if (i === ownarea)
            try {
                for (var _c = (e_1 = void 0, __values(Array.from(document.getElementsByClassName("area".concat(i))))), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var classelement = _d.value;
                    classelement.style.display = "";
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
        else
            try {
                for (var _e = (e_2 = void 0, __values(Array.from(document.getElementsByClassName("area".concat(i))))), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var classelement = _f.value;
                    classelement.style.display = "none";
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_2) throw e_2.error; }
            }
    }
}
