export default [
    '1.png',
    '2.png',
    '3.png',
    '4.png',
    '5.png',
    '6.png',
    'empty.png',
    'lawyerbadge.png',
];
