"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleRMC = void 0;
var client_1 = require("../../client");
// TODO BUG:
// this.viewport.music is an array. Therefore you must access elements
/**
 * Handles a music change to an arbitrary resource, with an offset in seconds.
 * @param {Array} args packet arguments
 */
var handleRMC = function (args) {
    client_1.client.viewport.music.pause();
    var music = client_1.client.viewport.music;
    // Music offset + drift from song loading
    music.totime = args[1];
    music.offset = new Date().getTime() / 1000;
    music.addEventListener("loadedmetadata", function () {
        music.currentTime += parseFloat(music.totime + (new Date().getTime() / 1000 - music.offset)).toFixed(3);
        music.play();
    }, false);
};
exports.handleRMC = handleRMC;
