"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendDE = void 0;
var client_1 = require("../../client");
/**
 * Sends delete evidence command.
 * @param {number} evidence id
 */
var sendDE = function (id) {
    client_1.client.sender.sendServer("DE#".concat(id, "#%"));
};
exports.sendDE = sendDE;
