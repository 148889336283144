"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendEE = void 0;
var client_1 = require("../../client");
var encoding_1 = require("../../encoding");
/**
 * Sends edit evidence command.
 * @param {number} evidence id
 * @param {string} evidence name
 * @param {string} evidence description
 * @param {string} evidence image filename
 */
var sendEE = function (id, name, desc, img) {
    client_1.client.sender.sendServer("EE#".concat(id, "#").concat((0, encoding_1.escapeChat)(name), "#").concat((0, encoding_1.escapeChat)(desc), "#").concat((0, encoding_1.escapeChat)(img), "#%"));
};
exports.sendEE = sendEE;
