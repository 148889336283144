"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleKK = void 0;
var encoding_1 = require("../../encoding");
var handleBans_1 = require("../../client/handleBans");
/**
 * Handles the kicked packet
 * @param {Array} args kick reason
 */
var handleKK = function (args) {
    (0, handleBans_1.handleBans)("Kicked", (0, encoding_1.safeTags)(args[1]));
};
exports.handleKK = handleKK;
