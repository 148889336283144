"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendHP = void 0;
var client_1 = require("../../client");
/**
 * Sends health point command.
 * @param {number} side the position
 * @param {number} hp the health point
 */
var sendHP = function (side, hp) {
    client_1.client.sender.sendServer("HP#".concat(side, "#").concat(hp, "#%"));
};
exports.sendHP = sendHP;
