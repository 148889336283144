"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.handle_ic_speaking = exports.setStartThirdTickCheck = exports.startThirdTickCheck = exports.setStartSecondTickCheck = exports.startSecondTickCheck = exports.setStartFirstTickCheck = exports.startFirstTickCheck = void 0;
var client_1 = require("../../client");
var appendICLog_1 = require("../../client/appendICLog");
var checkCallword_1 = require("../../client/checkCallword");
var setEmote_1 = require("../../client/setEmote");
var aoHost_1 = require("../../client/aoHost");
var shouts_1 = require("../constants/shouts");
var getAnimLength_1 = require("../../utils/getAnimLength");
var setChatbox_1 = require("../../dom/setChatbox");
var resizeChatbox_1 = require("../../dom/resizeChatbox");
var transparentPng_1 = require("../../constants/transparentPng");
var colors_1 = require("../constants/colors");
var aoml_1 = require("../../utils/aoml");
var attorneyMarkdown = (0, aoml_1.default)(aoHost_1.AO_HOST);
var setStartFirstTickCheck = function (val) { exports.startFirstTickCheck = val; };
exports.setStartFirstTickCheck = setStartFirstTickCheck;
var setStartSecondTickCheck = function (val) { exports.startSecondTickCheck = val; };
exports.setStartSecondTickCheck = setStartSecondTickCheck;
var setStartThirdTickCheck = function (val) { exports.startThirdTickCheck = val; };
exports.setStartThirdTickCheck = setStartThirdTickCheck;
/**
 * Sets a new emote.
 * This sets up everything before the tick() loops starts
 * a lot of things can probably be moved here, like starting the shout animation if there is one
 * TODO: the preanim logic, on the other hand, should probably be moved to tick()
 * @param {object} chatmsg the new chat message
 */
var handle_ic_speaking = function (playerChatMsg) { return __awaiter(void 0, void 0, void 0, function () {
    var charLayers, pairLayers, fg, gamewindow, waitingBox, eviBox, validSides, chatContainerBox, nameBoxInner, chatBoxInner, displayname, shoutSprite, shout, gifLength, setAside, skipoffset, effectName, badEffects, intensity, i, drop, baseEffectUrl, soundChecks, _a, error_1, output, _b, _c, letter, currentSelector;
    var e_1, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                client_1.client.viewport.setChatmsg(playerChatMsg);
                client_1.client.viewport.setTextNow("");
                client_1.client.viewport.setSfxPlayed(0);
                client_1.client.viewport.setTickTimer(0);
                client_1.client.viewport.setAnimating(true);
                exports.startFirstTickCheck = true;
                exports.startSecondTickCheck = false;
                exports.startThirdTickCheck = false;
                charLayers = document.getElementById("client_char");
                pairLayers = document.getElementById("client_pair_char");
                // stop updater
                clearTimeout(client_1.client.viewport.updater);
                // stop last sfx from looping any longer
                client_1.client.viewport.getSfxAudio().loop = false;
                fg = document.getElementById("client_fg");
                gamewindow = document.getElementById("client_gamewindow");
                waitingBox = document.getElementById("client_chatwaiting");
                // Reset CSS animation
                gamewindow.style.animation = "";
                waitingBox.style.opacity = "0";
                eviBox = document.getElementById("client_evi");
                if (client_1.client.viewport.getLastEvidence() !== client_1.client.viewport.getChatmsg().evidence) {
                    eviBox.style.opacity = "0";
                    eviBox.style.height = "0%";
                }
                client_1.client.viewport.setLastEvidence(client_1.client.viewport.getChatmsg().evidence);
                validSides = ["def", "pro", "wit"];
                if (validSides.includes(client_1.client.viewport.getChatmsg().side)) {
                    charLayers = document.getElementById("client_".concat(client_1.client.viewport.getChatmsg().side, "_char"));
                    pairLayers = document.getElementById("client_".concat(client_1.client.viewport.getChatmsg().side, "_pair_char"));
                }
                chatContainerBox = document.getElementById("client_chatcontainer");
                nameBoxInner = document.getElementById("client_inner_name");
                chatBoxInner = document.getElementById("client_inner_chat");
                displayname = document.getElementById("showname").checked &&
                    client_1.client.viewport.getChatmsg().showname !== ""
                    ? client_1.client.viewport.getChatmsg().showname
                    : client_1.client.viewport.getChatmsg().nameplate;
                // Clear out the last message
                chatBoxInner.innerText = client_1.client.viewport.getTextNow();
                nameBoxInner.innerText = displayname;
                if (client_1.client.viewport.getLastCharacter() !== client_1.client.viewport.getChatmsg().name) {
                    charLayers.style.opacity = "0";
                    pairLayers.style.opacity = "0";
                }
                client_1.client.viewport.setLastCharacter(client_1.client.viewport.getChatmsg().name);
                (0, appendICLog_1.appendICLog)(client_1.client.viewport.getChatmsg().content, client_1.client.viewport.getChatmsg().showname, client_1.client.viewport.getChatmsg().nameplate);
                (0, checkCallword_1.checkCallword)(client_1.client.viewport.getChatmsg().content, client_1.client.viewport.getSfxAudio());
                (0, setEmote_1.default)(aoHost_1.AO_HOST, client_1.client, client_1.client.viewport.getChatmsg().name.toLowerCase(), client_1.client.viewport.getChatmsg().sprite, "(a)", false, client_1.client.viewport.getChatmsg().side);
                if (client_1.client.viewport.getChatmsg().other_name) {
                    (0, setEmote_1.default)(aoHost_1.AO_HOST, client_1.client, client_1.client.viewport.getChatmsg().other_name.toLowerCase(), client_1.client.viewport.getChatmsg().other_emote, "(a)", false, client_1.client.viewport.getChatmsg().side);
                }
                shoutSprite = (document.getElementById("client_shout"));
                shout = shouts_1.SHOUTS[client_1.client.viewport.getChatmsg().objection];
                if (shout) {
                    // Hide message box
                    chatContainerBox.style.opacity = "0";
                    if (client_1.client.viewport.getChatmsg().objection === 4) {
                        shoutSprite.src = "".concat(aoHost_1.AO_HOST, "characters/").concat(encodeURI(client_1.client.viewport.getChatmsg().name.toLowerCase()), "/custom.gif");
                    }
                    else {
                        shoutSprite.src = client_1.client.resources[shout].src;
                        shoutSprite.style.animation = "bubble 700ms steps(10, jump-both)";
                    }
                    shoutSprite.style.opacity = "1";
                    client_1.client.viewport.shoutaudio.src = "".concat(aoHost_1.AO_HOST, "characters/").concat(encodeURI(client_1.client.viewport.getChatmsg().name.toLowerCase()), "/").concat(shout, ".opus");
                    client_1.client.viewport.shoutaudio.play();
                    client_1.client.viewport.setShoutTimer(client_1.client.resources[shout].duration);
                }
                else {
                    client_1.client.viewport.setShoutTimer(0);
                }
                client_1.client.viewport.getChatmsg().startpreanim = true;
                gifLength = 0;
                if (!(client_1.client.viewport.getChatmsg().type === 1 && client_1.client.viewport.getChatmsg().preanim !== "-")) return [3 /*break*/, 2];
                //we have a preanim
                chatContainerBox.style.opacity = "0";
                return [4 /*yield*/, (0, getAnimLength_1.default)("".concat(aoHost_1.AO_HOST, "characters/").concat(encodeURI(client_1.client.viewport.getChatmsg().name.toLowerCase()), "/").concat(encodeURI(client_1.client.viewport.getChatmsg().preanim)))];
            case 1:
                gifLength = _e.sent();
                client_1.client.viewport.getChatmsg().startspeaking = false;
                return [3 /*break*/, 3];
            case 2:
                client_1.client.viewport.getChatmsg().startspeaking = true;
                if (client_1.client.viewport.getChatmsg().content.trim() !== "")
                    chatContainerBox.style.opacity = "1";
                _e.label = 3;
            case 3:
                client_1.client.viewport.getChatmsg().preanimdelay = gifLength;
                setAside = {
                    position: client_1.client.viewport.getChatmsg().side,
                    showSpeedLines: false,
                    showDesk: false,
                };
                skipoffset = false;
                if (client_1.client.viewport.getChatmsg().type === 5) {
                    setAside.showSpeedLines = true;
                    setAside.showDesk = false;
                    client_1.client.viewport.set_side(setAside);
                }
                else {
                    switch (Number(client_1.client.viewport.getChatmsg().deskmod)) {
                        case 0: //desk is hidden
                            setAside.showSpeedLines = false;
                            setAside.showDesk = false;
                            client_1.client.viewport.set_side(setAside);
                            break;
                        case 1: //desk is shown
                            setAside.showSpeedLines = false;
                            setAside.showDesk = true;
                            client_1.client.viewport.set_side(setAside);
                            break;
                        case 2: //desk is hidden during preanim, but shown during idle/talk
                            setAside.showSpeedLines = false;
                            setAside.showDesk = false;
                            client_1.client.viewport.set_side(setAside);
                            break;
                        case 3: //opposite of 2
                            setAside.showSpeedLines = false;
                            setAside.showDesk = false;
                            client_1.client.viewport.set_side(setAside);
                            break;
                        case 4: //desk is hidden, character offset is ignored, pair character is hidden during preanim, normal behavior during idle/talk
                            setAside.showSpeedLines = false;
                            setAside.showDesk = false;
                            client_1.client.viewport.set_side(setAside);
                            skipoffset = true;
                            break;
                        case 5: //opposite of 4
                            setAside.showSpeedLines = false;
                            setAside.showDesk = true;
                            client_1.client.viewport.set_side(setAside);
                            break;
                        default:
                            setAside.showSpeedLines = false;
                            setAside.showDesk = true;
                            client_1.client.viewport.set_side(setAside);
                            break;
                    }
                }
                (0, setChatbox_1.setChatbox)(client_1.client.viewport.getChatmsg().chatbox);
                (0, resizeChatbox_1.resizeChatbox)();
                if (client_1.client.viewport.getChatmsg().chatbox === "") {
                    // No chatbox means hide it
                    chatContainerBox.style.opacity = "0";
                }
                if (!skipoffset) {
                    // Flip the character
                    charLayers.style.transform =
                        client_1.client.viewport.getChatmsg().flip === 1 ? "scaleX(-1)" : "scaleX(1)";
                    pairLayers.style.transform =
                        client_1.client.viewport.getChatmsg().other_flip === 1 ? "scaleX(-1)" : "scaleX(1)";
                    // Shift by the horizontal offset
                    switch (client_1.client.viewport.getChatmsg().side) {
                        case "wit":
                            pairLayers.style.left = "".concat(200 + Number(client_1.client.viewport.getChatmsg().other_offset[0]), "%");
                            charLayers.style.left = "".concat(200 + Number(client_1.client.viewport.getChatmsg().self_offset[0]), "%");
                            break;
                        case "pro":
                            pairLayers.style.left = "".concat(400 + Number(client_1.client.viewport.getChatmsg().other_offset[0]), "%");
                            charLayers.style.left = "".concat(400 + Number(client_1.client.viewport.getChatmsg().self_offset[0]), "%");
                            break;
                        default:
                            pairLayers.style.left = "".concat(Number(client_1.client.viewport.getChatmsg().other_offset[0]), "%");
                            charLayers.style.left = "".concat(Number(client_1.client.viewport.getChatmsg().self_offset[0]), "%");
                            break;
                    }
                    // New vertical offsets
                    pairLayers.style.top = "".concat(Number(client_1.client.viewport.getChatmsg().other_offset[1]), "%");
                    charLayers.style.top = "".concat(Number(client_1.client.viewport.getChatmsg().self_offset[1]), "%");
                }
                client_1.client.viewport.blipChannels.forEach(function (channel) {
                    return (channel.src = "".concat(aoHost_1.AO_HOST, "sounds/blips/").concat(encodeURI(client_1.client.viewport.getChatmsg().blips.toLowerCase()), ".opus"));
                });
                // process markup
                if (client_1.client.viewport.getChatmsg().content.startsWith("~~")) {
                    chatBoxInner.style.textAlign = "center";
                    client_1.client.viewport.getChatmsg().content = client_1.client.viewport.getChatmsg().content.substring(2, client_1.client.viewport.getChatmsg().content.length);
                }
                else {
                    chatBoxInner.style.textAlign = "inherit";
                }
                // apply effects
                fg.style.animation = "";
                effectName = client_1.client.viewport.getChatmsg().effects[0].toLowerCase();
                badEffects = ["", "-", "none"];
                if (effectName.startsWith("rain")) {
                    document.getElementById("effect_css").href = "styles/effects/rain.css";
                    intensity = 200;
                    if (effectName.endsWith("weak")) {
                        intensity = 100;
                    }
                    else if (effectName.endsWith("strong")) {
                        intensity = 400;
                    }
                    if (intensity < fg.childElementCount)
                        fg.innerHTML = '';
                    else
                        intensity = intensity - fg.childElementCount;
                    for (i = 0; i < intensity; i++) {
                        drop = document.createElement("p");
                        drop.style.left = (Math.random() * 100) + "%";
                        drop.style.animationDelay = String(Math.random()) + "s";
                        fg.appendChild(drop);
                    }
                }
                else if (client_1.client.viewport.getChatmsg().effects[0] &&
                    !badEffects.includes(effectName)) {
                    document.getElementById("effect_css").href = "";
                    fg.innerHTML = '';
                    baseEffectUrl = "".concat(aoHost_1.AO_HOST, "themes/default/effects/");
                    fg.src = "".concat(baseEffectUrl).concat(encodeURI(effectName), ".webp");
                }
                else {
                    fg.innerHTML = '';
                    fg.src = transparentPng_1.default;
                }
                charLayers.style.opacity = "1";
                soundChecks = ["0", "1", "", undefined];
                if (soundChecks.some(function (check) { return client_1.client.viewport.getChatmsg().sound === check; })) {
                    client_1.client.viewport.getChatmsg().sound = client_1.client.viewport.getChatmsg().effects[2];
                }
                _e.label = 4;
            case 4:
                _e.trys.push([4, 6, , 7]);
                _a = client_1.client.viewport.getChatmsg();
                return [4 /*yield*/, attorneyMarkdown.applyMarkdown(client_1.client.viewport.getChatmsg().content, colors_1.COLORS[client_1.client.viewport.getChatmsg().color])];
            case 5:
                _a.parsed = _e.sent();
                return [3 /*break*/, 7];
            case 6:
                error_1 = _e.sent();
                console.warn("markdown failed");
                output = [];
                try {
                    for (_b = __values(client_1.client.viewport.getChatmsg().content), _c = _b.next(); !_c.done; _c = _b.next()) {
                        letter = _c.value;
                        currentSelector = document.createElement('span');
                        currentSelector.innerHTML = letter;
                        currentSelector.className = "text_".concat(colors_1.COLORS[client_1.client.viewport.getChatmsg().color]);
                        output.push(currentSelector);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_d = _b.return)) _d.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                client_1.client.viewport.getChatmsg().parsed = output;
                return [3 /*break*/, 7];
            case 7:
                client_1.client.viewport.chat_tick();
                return [2 /*return*/];
        }
    });
}); };
exports.handle_ic_speaking = handle_ic_speaking;
