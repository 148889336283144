"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addTrack = void 0;
var client_1 = require("../client");
var encoding_1 = require("../encoding");
var paths_1 = require("../utils/paths");
var addTrack = function (trackname) {
    var newentry = document.createElement("OPTION");
    var songName = (0, paths_1.getFilenameFromPath)(trackname);
    newentry.text = (0, encoding_1.safeTags)((0, encoding_1.unescapeChat)(songName));
    newentry.value = trackname;
    (document.getElementById("client_musiclist")).options.add(newentry);
    client_1.client.musics.push(trackname);
};
exports.addTrack = addTrack;
