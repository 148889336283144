"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleFM = void 0;
var client_1 = require("../../client");
var addTrack_1 = require("../../client/addTrack");
/**
 * Handles updated music list
 * @param {Array} args packet arguments
 */
var handleFM = function (args) {
    client_1.client.resetMusicList();
    for (var i = 1; i < args.length - 1; i++) {
        // Check when found the song for the first time
        (0, addTrack_1.addTrack)(args[i]);
    }
};
exports.handleFM = handleFM;
