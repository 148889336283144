"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendMA = void 0;
var client_1 = require("../../client");
/**
 * Sends mod command.
 * @param {number} id player id
 * @param {number} length in hours
 * @param {string} reason player message
 */
var sendMA = function (id, length, reason) {
    client_1.client.sender.sendServer("MA#".concat(id, "#").concat(length, "#").concat(reason, "#%"));
};
exports.sendMA = sendMA;
