"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.opusCheck = opusCheck;
/**
 * Triggered when there was an error loading a sound
 * @param {HTMLAudioElement} image the element containing the missing sound
 */
function opusCheck(channel) {
    var audio = channel.src;
    if (audio === "") {
        return;
    }
    console.warn("failed to load sound ".concat(channel.src));
    var oldsrc = "";
    var newsrc = "";
    oldsrc = channel.src;
    if (!oldsrc.endsWith(".opus")) {
        newsrc = oldsrc.replace(".mp3", ".opus");
        newsrc = newsrc.replace(".wav", ".opus");
        channel.src = newsrc; // unload so the old sprite doesn't persist
    }
}
window.opusCheck = opusCheck;
