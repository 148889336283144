"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handlePR = void 0;
function addPlayer(playerID) {
    var list = document.getElementById("client_playerlist");
    var playerRow = list.insertRow();
    playerRow.id = "client_playerlist_entry".concat(playerID);
    playerRow.className = "area0";
    var imgCell = playerRow.insertCell(0);
    imgCell.style.width = "64px";
    var img = document.createElement('img');
    imgCell.appendChild(img);
    var name = document.createTextNode('Unknown');
    var charNameCell = playerRow.insertCell(1);
    charNameCell.appendChild(name);
    var showNameCell = playerRow.insertCell(2);
    showNameCell.appendChild(name);
    var oocNameCell = playerRow.insertCell(3);
    oocNameCell.appendChild(name);
    var kickCell = playerRow.insertCell(4);
    kickCell.style.width = "64px";
    var kick = document.createElement("button");
    kick.innerText = "Kick";
    kick.onclick = function () { window.kickPlayer(playerID); };
    kickCell.appendChild(kick);
    var banCell = playerRow.insertCell(5);
    banCell.style.width = "64px";
    var ban = document.createElement("button");
    ban.innerText = "Ban";
    ban.onclick = function () { window.banPlayer(playerID); };
    banCell.appendChild(ban);
}
function removePlayer(playerID) {
    var playerRow = document.getElementById("client_playerlist_entry".concat(playerID));
    playerRow.remove();
}
/**
  * Handles a player joining or leaving
  * @param {Array} args packet arguments
  */
var handlePR = function (args) {
    var playerID = Number(args[1]);
    if (Number(args[2]) === 0)
        addPlayer(playerID);
    else if (Number(args[2]) === 1)
        removePlayer(playerID);
};
exports.handlePR = handlePR;
