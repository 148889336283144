"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLastICMessageTime = exports.lastICMessageTime = exports.clientState = exports.delay = exports.setBanned = exports.banned = exports.setExtraFeatures = exports.extrafeatures = exports.setSelectedShout = exports.selectedShout = exports.setSelectedMenu = exports.selectedMenu = exports.setOldLoading = exports.oldLoading = exports.UPDATE_INTERVAL = exports.setClient = exports.client = exports.setCHATBOX = exports.CHATBOX = void 0;
/*
 * Glorious webAO
 * made by sD, refactored by oldmud0 and Qubrick
 * credits to aleks for original idea and source
 */
var isLowMemory_1 = require("./client/isLowMemory");
var fingerprintjs_1 = require("@fingerprintjs/fingerprintjs");
var index_1 = require("./client/sender/index");
var queryParser_1 = require("./utils/queryParser");
var getResources_js_1 = require("./utils/getResources.js");
var viewport_1 = require("./viewport/viewport");
var events_1 = require("events");
var onReplayGo_1 = require("./dom/onReplayGo");
var packetHandler_1 = require("./packets/packetHandler");
var loadResources_1 = require("./client/loadResources");
var aoHost_1 = require("./client/aoHost");
var fetchLists_1 = require("./client/fetchLists");
var getCookie_1 = require("./utils/getCookie");
var _a = (0, queryParser_1.default)(), serverIP = _a.ip, connect = _a.connect, mode = _a.mode, theme = _a.theme, serverName = _a.serverName;
document.title = serverName;
var setCHATBOX = function (val) {
    exports.CHATBOX = val;
};
exports.setCHATBOX = setCHATBOX;
var setClient = function (val) {
    exports.client = val;
};
exports.setClient = setClient;
exports.UPDATE_INTERVAL = 60;
/**
 * Toggles AO1-style loading using paginated music packets for mobile platforms.
 * The old loading uses more smaller packets instead of a single big one,
 * which caused problems on low-memory devices in the past.
 */
exports.oldLoading = false;
var setOldLoading = function (val) {
    console.warn("old loading set to " + val);
    exports.oldLoading = val;
};
exports.setOldLoading = setOldLoading;
// presettings
exports.selectedMenu = 1;
var setSelectedMenu = function (val) {
    exports.selectedMenu = val;
};
exports.setSelectedMenu = setSelectedMenu;
exports.selectedShout = 0;
var setSelectedShout = function (val) {
    exports.selectedShout = val;
};
exports.setSelectedShout = setSelectedShout;
exports.extrafeatures = [];
var setExtraFeatures = function (val) {
    exports.extrafeatures = val;
};
exports.setExtraFeatures = setExtraFeatures;
exports.banned = false;
var setBanned = function (val) {
    exports.banned = val;
};
exports.setBanned = setBanned;
var hdid;
var fpPromise = fingerprintjs_1.default.load();
fpPromise
    .then(function (fp) { return fp.get(); })
    .then(function (result) {
    hdid = result.visitorId;
    var connectionString = connect;
    if (!connectionString && mode !== "replay") {
        if (serverIP) {
            // if connectionString is not set, try IP
            // and just guess ws, though it could be wss
            connectionString = "ws://".concat(serverIP);
        }
        else {
            alert("No connection string specified!");
            return;
        }
    }
    if (window.location.protocol === "https:" && connectionString.startsWith("ws://")) {
        // If protocol is https: and connectionString is ws://
        // We have a problem, since it's impossible to connect to ws:// from https://
        // Connection will fail, but at least warn the user
        alert('WS not supported on HTTPS. Please try removing the s from https:// at the start of the URL bar. (You might have to click inside the URL bar to see it)');
    }
    exports.client = new Client(connectionString);
    exports.client.connect();
    exports.client.hdid = hdid;
    (0, isLowMemory_1.isLowMemory)();
    (0, loadResources_1.loadResources)();
});
var delay = function (ms) { return new Promise(function (res) { return setTimeout(res, ms); }); };
exports.delay = delay;
var clientState;
(function (clientState) {
    clientState[clientState["NotConnected"] = 0] = "NotConnected";
    // Should be set once the client has established a connection
    clientState[clientState["Connected"] = 1] = "Connected";
    // Should be set once the client has joined the server (after handshake)
    clientState[clientState["Joined"] = 2] = "Joined";
})(clientState || (exports.clientState = clientState = {}));
exports.lastICMessageTime = new Date(0);
var setLastICMessageTime = function (val) {
    exports.lastICMessageTime = val;
};
exports.setLastICMessageTime = setLastICMessageTime;
var Client = /** @class */ (function (_super) {
    __extends(Client, _super);
    function Client(connectionString) {
        var _this = _super.call(this) || this;
        _this.state = clientState.NotConnected;
        _this.connect = function () {
            _this.on("open", _this.onOpen.bind(_this));
            _this.on("close", _this.onClose.bind(_this));
            _this.on("message", _this.onMessage.bind(_this));
            _this.on("error", _this.onError.bind(_this));
            if (mode !== "replay") {
                _this.serv = new WebSocket(connectionString);
                // Assign the websocket events
                _this.serv.addEventListener("open", _this.emit.bind(_this, "open"));
                _this.serv.addEventListener("close", _this.emit.bind(_this, "close"));
                _this.serv.addEventListener("message", _this.emit.bind(_this, "message"));
                _this.serv.addEventListener("error", _this.emit.bind(_this, "error"));
                // If the client is still not connected 5 seconds after attempting to join
                // It's fair to assume that the server is not reachable
                setTimeout(function () {
                    if (_this.state === clientState.NotConnected) {
                        _this.serv.close();
                    }
                }, 5000);
            }
            else {
                _this.joinServer();
            }
        };
        _this.hp = [0, 0];
        _this.playerID = 1;
        _this.charID = -1;
        _this.char_list_length = 0;
        _this.evidence_list_length = 0;
        _this.music_list_length = 0;
        _this.testimonyID = 0;
        _this.chars = [];
        _this.emotes = [];
        _this.evidences = [];
        _this.area = 0;
        _this.areas = [];
        _this.musics = [];
        _this.musics_time = false;
        _this.callwords = [];
        _this.manifest = [];
        _this.resources = (0, getResources_js_1.default)(aoHost_1.AO_HOST, theme);
        _this.selectedEmote = -1;
        _this.selectedEvidence = -1;
        _this.checkUpdater = null;
        _this.sender = index_1.sender;
        _this.viewport = (0, viewport_1.default)();
        _this._lastTimeICReceived = new Date(0);
        _this.partial_packet = false;
        _this.temp_packet = "";
        loadResources_1.loadResources;
        isLowMemory_1.isLowMemory;
        return _this;
    }
    Object.defineProperty(Client.prototype, "character", {
        /**
       * Gets the current player's character.
       */
        get: function () {
            return this.chars[this.charID];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "emote", {
        /**
       * Gets the player's currently selected emote.
       */
        get: function () {
            return this.emotes[this.selectedEmote];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "evidence", {
        /**
       * Gets the current evidence ID unless the player doesn't want to present any evidence
       */
        get: function () {
            return document.getElementById("button_present").classList.contains("dark")
                ? this.selectedEvidence
                : -1;
        },
        enumerable: false,
        configurable: true
    });
    /**
   * Hook for sending messages to the client
   * @param {string} message the message to send
   */
    Client.prototype.handleSelf = function (message) {
        var _this = this;
        var message_event = new MessageEvent("websocket", { data: message });
        setTimeout(function () { return _this.onMessage(message_event); }, 1);
    };
    /**
   * Begins the handshake process by sending an identifier
   * to the server.
   */
    Client.prototype.joinServer = function () {
        var _this = this;
        this.sender.sendServer("HI#".concat(hdid, "#%"));
        if ((0, getCookie_1.default)("hdid") !== hdid) {
            this.sender.sendServer((0, getCookie_1.default)("hdid"));
            document.getElementById("client_secondfactor").style.display = "block";
            document.getElementById("client_charselect").remove();
            document.getElementById("client_ooc").remove();
        }
        if (mode !== "replay") {
            this.checkUpdater = setInterval(function () { return _this.sender.sendCheck(); }, 5000);
        }
    };
    /**
   * Triggered when a connection is established to the server.
   */
    Client.prototype.onOpen = function (_e) {
        exports.client.state = clientState.Connected;
        exports.client.joinServer();
    };
    /**
   * Triggered when the connection to the server closes.
   * @param {CloseEvent} e
   */
    Client.prototype.onClose = function (e) {
        exports.client.state = clientState.NotConnected;
        console.error("The connection was closed: ".concat(e.reason, " (").concat(e.code, ")"));
        if (exports.extrafeatures.length == 0 && exports.banned === false) {
            document.getElementById("client_errortext").textContent =
                "Could not connect to the server";
        }
        document.getElementById("client_waiting").style.display = "block";
        document.getElementById("client_error").style.display = "flex";
        document.getElementById("client_loading").style.display = "none";
        document.getElementById("error_id").textContent = String(e.code);
        this.cleanup();
    };
    /**
   * Triggered when a packet is received from the server.
   * @param {MessageEvent} e
   */
    Client.prototype.onMessage = function (e) {
        var msg = e.data;
        console.debug("S: ".concat(msg));
        this.handle_server_packet(msg);
    };
    /**
   * Decode the packet
   * @param {MessageEvent} e
   */
    Client.prototype.handle_server_packet = function (p_data) {
        var e_1, _a;
        var in_data = p_data;
        if (!p_data.endsWith("%")) {
            this.partial_packet = true;
            this.temp_packet = this.temp_packet + in_data;
            console.log("Partial packet");
            return;
        }
        else {
            if (this.partial_packet) {
                in_data = this.temp_packet + in_data;
                this.temp_packet = "";
                this.partial_packet = false;
            }
        }
        var packet_list = in_data.split("%");
        try {
            for (var packet_list_1 = __values(packet_list), packet_list_1_1 = packet_list_1.next(); !packet_list_1_1.done; packet_list_1_1 = packet_list_1.next()) {
                var packet = packet_list_1_1.value;
                var f_contents = void 0;
                // Packet should *always* end with #
                if (packet.endsWith("#")) {
                    f_contents = packet.slice(0, -1).split("#");
                }
                // But, if it somehow doesn't, we should still be able to handle it
                else {
                    f_contents = packet.split("#");
                }
                // Empty packets are suspicious!
                if (f_contents.length == 0) {
                    console.warn("WARNING: Empty packet received from server, skipping...");
                    continue;
                }
                // Take the first arg as the command
                var command = f_contents[0];
                if (command !== "") {
                    // The rest is contents of the packet
                    packetHandler_1.packetHandler.has(command)
                        ? packetHandler_1.packetHandler.get(command)(f_contents)
                        : console.warn("Invalid packet header ".concat(command));
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (packet_list_1_1 && !packet_list_1_1.done && (_a = packet_list_1.return)) _a.call(packet_list_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
   * Triggered when an network error occurs.
   * @param {ErrorEvent} e
   */
    Client.prototype.onError = function (e) {
        exports.client.state = clientState.NotConnected;
        console.error("A network error occurred");
        console.error(e);
        document.getElementById("client_error").style.display = "flex";
        this.cleanup();
    };
    /**
   * Stop sending keepalives to the server.
   */
    Client.prototype.cleanup = function () {
        clearInterval(this.checkUpdater);
        this.serv.close();
    };
    /**
   * Parse the lines in the OOC and play them
   * @param {*} args packet arguments
   */
    Client.prototype.handleReplay = function () {
        var ooclog = document.getElementById("client_ooclog");
        var rawLog = false;
        var rtime = Number(document.getElementById("client_replaytimer").value);
        var clines = ooclog.value.split(/\r?\n/);
        if (clines[0]) {
            var currentLine = String(clines[0]);
            this.handleSelf(currentLine);
            ooclog.value = clines.slice(1).join("\r\n");
            if (currentLine.substr(0, 4) === "wait" && rawLog === false) {
                rtime = Number(currentLine.split("#")[1]);
            }
            else if (currentLine.substr(0, 2) !== "MS") {
                rtime = 0;
            }
            setTimeout(function () { return (0, onReplayGo_1.onReplayGo)(null); }, rtime);
        }
    };
    Client.prototype.resetMusicList = function () {
        this.musics = [];
        document.getElementById("client_musiclist").innerHTML = "";
    };
    Client.prototype.resetAreaList = function () {
        this.areas = [];
        document.getElementById("areas").innerHTML = "";
        (0, fetchLists_1.fetchBackgroundList)();
        (0, fetchLists_1.fetchEvidenceList)();
        (0, fetchLists_1.fetchCharacterList)();
    };
    return Client;
}(events_1.EventEmitter));
exports.default = Client;
