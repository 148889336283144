"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleHI = void 0;
var client_1 = require("../../client");
var version = process.env.npm_package_version;
/**
 * Handle the player
 * @param {Array} args packet arguments
 */
var handleHI = function (_args) {
    client_1.client.sender.sendSelf("ID#1#webAO#".concat(version, "#%"));
    client_1.client.sender.sendSelf("FL#fastloading#yellowtext#cccc_ic_support#flipping#looping_sfx#effects#%");
};
exports.handleHI = handleHI;
