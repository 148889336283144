"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkCallword = checkCallword;
var client_1 = require("../client");
var aoHost_1 = require("./aoHost");
/**
 * check if the message contains an entry on our callword list
 * @param {string} message
 */
function checkCallword(message, sfxAudio) {
    client_1.client.callwords.forEach(testCallword);
    function testCallword(item) {
        if (item !== "" && message.toLowerCase().includes(item.toLowerCase())) {
            sfxAudio.pause();
            sfxAudio.src = "".concat(aoHost_1.AO_HOST, "sounds/general/sfx-gallery.opus");
            sfxAudio.play();
        }
    }
}
