"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pickEmotion = pickEmotion;
var client_1 = require("../client");
/**
 * Highlights and selects an emotion for in-character chat.
 * @param {string} emo the new emotion to be selected
 */
function pickEmotion(emo) {
    try {
        if (client_1.client.selectedEmote !== -1) {
            document.getElementById("emo_".concat(client_1.client.selectedEmote)).className =
                "emote_button";
        }
    }
    catch (err) {
        // do nothing
    }
    client_1.client.selectedEmote = emo;
    document.getElementById("emo_".concat(emo)).className = "emote_button dark";
    document.getElementById("sendsfx").checked =
        client_1.client.emote.sfx.length > 1;
    document.getElementById("sendpreanim").checked =
        client_1.client.emote.zoom == 1;
    document.getElementById("client_inputbox").focus();
}
window.pickEmotion = pickEmotion;
