"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendRT = void 0;
var client_1 = require("../../client");
/**
 * Sends testimony command.
 * @param {string} testimony type
 */
var sendRT = function (testimony) {
    client_1.client.sender.sendServer("RT#".concat(testimony, "#%"));
};
exports.sendRT = sendRT;
