"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleDONE = void 0;
var queryParser_1 = require("../../utils/queryParser");
var client_1 = require("../../client");
var mode = (0, queryParser_1.default)().mode;
/**
   * Handles the handshake completion packet, meaning the player
   * is ready to select a character.
   *
   * @param {Array} args packet arguments
   */
var handleDONE = function (_args) {
    // DONE packet signals that the handshake is complete
    client_1.client.state = client_1.clientState.Joined;
    document.getElementById("client_loading").style.display = "none";
    if (mode === "watch") {
        // Spectators don't need to pick a character
        document.getElementById("client_waiting").style.display = "none";
    }
};
exports.handleDONE = handleDONE;
