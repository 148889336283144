"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleASS = void 0;
var aoHost_1 = require("../../client/aoHost");
/**
* new asset url!!
* @param {Array} args packet arguments
*/
var handleASS = function (args) {
    if (args[1] !== "None")
        (0, aoHost_1.setAOhost)(args[1]);
};
exports.handleASS = handleASS;
