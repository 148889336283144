"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleAUTH = void 0;
/**
* i am mod now
* @param {Array} args packet arguments
*/
var handleAUTH = function (args) {
    (document.getElementById("mod_ui")).href = "styles/mod.css";
};
exports.handleAUTH = handleAUTH;
