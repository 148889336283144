"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.area_click = area_click;
var client_1 = require("../client");
var updatePlayerAreas_1 = require("./updatePlayerAreas");
/**
 * Triggered when an item on the area list is clicked.
 * @param {HTMLElement} el
 */
function area_click(el) {
    var area = client_1.client.areas[el.id.substring(4)].name;
    client_1.client.sender.sendMusicChange(area);
    var areaHr = document.createElement("div");
    areaHr.className = "hrtext";
    areaHr.textContent = "switched to ".concat(el.textContent);
    document.getElementById("client_log").appendChild(areaHr);
    client_1.client.area = Number(el.id.substring(4));
    (0, updatePlayerAreas_1.updatePlayerAreas)(client_1.client.area);
}
window.area_click = area_click;
