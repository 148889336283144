"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleSM = void 0;
var client_1 = require("../../client");
var addTrack_1 = require("../../client/addTrack");
var isAudio_1 = require("../../client/isAudio");
var fixLastArea_1 = require("../../client/fixLastArea");
var createArea_1 = require("../../client/createArea");
/**
 * Handles incoming music information, containing all music in one packet.
 * @param {Array} args packet arguments
 */
var handleSM = function (args) {
    document.getElementById("client_loadingtext").innerHTML = "Loading Music ";
    client_1.client.resetMusicList();
    client_1.client.resetAreaList();
    client_1.client.musics_time = false;
    document.getElementById("client_loadingtext").innerHTML = "Loading Music";
    for (var i = 1; i < args.length - 1; i++) {
        // Check when found the song for the first time
        var trackname = args[i];
        var trackindex = i - 1;
        if (client_1.client.musics_time) {
            (0, addTrack_1.addTrack)(trackname);
        }
        else if ((0, isAudio_1.isAudio)(trackname)) {
            client_1.client.musics_time = true;
            (0, fixLastArea_1.fix_last_area)();
            (0, addTrack_1.addTrack)(trackname);
        }
        else {
            (0, createArea_1.createArea)(trackindex, trackname);
        }
    }
    // Music done, carry on
    client_1.client.sender.sendServer("RD#%");
};
exports.handleSM = handleSM;
