"use strict";
/* eslint @typescript-eslint/no-explicit-any: "off" */
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * set a cookie
 * the version from w3schools expects these to expire
 * @param {string} cname The name of the cookie to return
 * @param {any} value The value of that cookie option
 */
var setCookie = function (cname, value) {
    document.cookie = "".concat(cname, "=").concat(value, ";SameSite=Strict");
};
exports.default = setCookie;
