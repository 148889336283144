"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sender = void 0;
var sendIC_1 = require("./sendIC");
var sendSelf_1 = require("./sendSelf");
var sendServer_1 = require("./sendServer");
var sendCheck_1 = require("./sendCheck");
var sendHP_1 = require("./sendHP");
var sendOOC_1 = require("./sendOOC");
var sendCharacter_1 = require("./sendCharacter");
var sendRT_1 = require("./sendRT");
var sendMusicChange_1 = require("./sendMusicChange");
var sendZZ_1 = require("./sendZZ");
var sendEE_1 = require("./sendEE");
var sendDE_1 = require("./sendDE");
var sendPE_1 = require("./sendPE");
var sendMA_1 = require("./sendMA");
exports.sender = {
    sendIC: sendIC_1.sendIC,
    sendSelf: sendSelf_1.sendSelf,
    sendServer: sendServer_1.sendServer,
    sendCheck: sendCheck_1.sendCheck,
    sendHP: sendHP_1.sendHP,
    sendOOC: sendOOC_1.sendOOC,
    sendCharacter: sendCharacter_1.sendCharacter,
    sendRT: sendRT_1.sendRT,
    sendMusicChange: sendMusicChange_1.sendMusicChange,
    sendZZ: sendZZ_1.sendZZ,
    sendEE: sendEE_1.sendEE,
    sendDE: sendDE_1.sendDE,
    sendPE: sendPE_1.sendPE,
    sendMA: sendMA_1.sendMA
};
