"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleRM = void 0;
var client_1 = require("../../client");
var music_js_1 = require("../../constants/music.js");
/**
   * we are asking ourselves what characters there are
   * @param {Array} args packet arguments
   */
var handleRM = function (_args) {
    client_1.client.sender.sendSelf("SM#".concat(music_js_1.default.join("#"), "#%"));
};
exports.handleRM = handleRM;
