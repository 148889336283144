"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIndexFromSelect = getIndexFromSelect;
/**
 * Find index of anything in select box.
 * @param {string} select_box the select element name
 * @param {string} value the value that need to be compared
 */
function getIndexFromSelect(select_box, value) {
    // Find if icon alraedy existed in select box
    var select_element = document.getElementById(select_box);
    for (var i = 1; i < select_element.length; ++i) {
        if (select_element.options[i].value === value) {
            return i;
        }
    }
    return 0;
}
window.getIndexFromSelect = getIndexFromSelect;
