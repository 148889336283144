"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendZZ = void 0;
var client_1 = require("../../client");
/**
 * Sends call mod command.
 * @param {string} message to mod
 */
var sendZZ = function (msg) {
    if (client_1.extrafeatures.includes("modcall_reason")) {
        client_1.client.sender.sendServer("ZZ#".concat(msg, "#%"));
    }
    else {
        client_1.client.sender.sendServer("ZZ#%");
    }
};
exports.sendZZ = sendZZ;
