"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createShoutAudio = void 0;
var aoHost_1 = require("../../client/aoHost");
var createShoutAudio = function () {
    var shoutAudio = document.getElementById("client_shoutaudio");
    shoutAudio.src = "".concat(aoHost_1.AO_HOST, "misc/default/objection.opus");
    return shoutAudio;
};
exports.createShoutAudio = createShoutAudio;
