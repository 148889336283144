"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setChatbox = setChatbox;
var client_1 = require("../client");
var chatboxes_js_1 = require("../styles/chatbox/chatboxes.js");
var setCookie_1 = require("../utils/setCookie");
/**
 * Set the style of the chatbox
 */
function setChatbox(setstyle) {
    var chatbox_theme = (document.getElementById("chatbox_theme"));
    var themeselect = (document.getElementById("client_chatboxselect"));
    (0, client_1.setCHATBOX)(themeselect.value);
    (0, setCookie_1.default)("chatbox", client_1.CHATBOX);
    if (client_1.CHATBOX === "dynamic") {
        var style = setstyle.replace("chat", "");
        if (chatboxes_js_1.default.includes(style)) {
            chatbox_theme.href = "styles/chatbox/".concat(style, ".css");
        }
        else {
            chatbox_theme.href = "styles/chatbox/aa.css";
        }
    }
    else {
        chatbox_theme.href = "styles/chatbox/".concat(client_1.CHATBOX, ".css");
    }
}
window.setChatbox = setChatbox;
