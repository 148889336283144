"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendPE = void 0;
var client_1 = require("../../client");
var encoding_1 = require("../../encoding");
/**
 * Sends add evidence command.
 * @param {string} evidence name
 * @param {string} evidence description
 * @param {string} evidence image filename
 */
var sendPE = function (name, desc, img) {
    client_1.client.sender.sendServer("PE#".concat((0, encoding_1.escapeChat)(name), "#").concat((0, encoding_1.escapeChat)(desc), "#").concat((0, encoding_1.escapeChat)(img), "#%"));
};
exports.sendPE = sendPE;
