"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchManifest = exports.fetchEvidenceList = exports.fetchCharacterList = exports.fetchBackgroundList = void 0;
var client_1 = require("../client");
var aoHost_1 = require("./aoHost");
var request_js_1 = require("../services/request.js");
var fetchBackgroundList = function () { return __awaiter(void 0, void 0, void 0, function () {
    var bgdata, bg_array, bg_select_1, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, (0, request_js_1.request)("".concat(aoHost_1.AO_HOST, "backgrounds.json"))];
            case 1:
                bgdata = _a.sent();
                bg_array = JSON.parse(bgdata);
                bg_select_1 = document.getElementById("bg_select");
                bg_select_1.innerHTML = "";
                bg_select_1.add(new Option("Custom", "0"));
                bg_array.forEach(function (background) {
                    bg_select_1.add(new Option(background));
                });
                return [3 /*break*/, 3];
            case 2:
                err_1 = _a.sent();
                console.warn("there was no backgrounds.json file");
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.fetchBackgroundList = fetchBackgroundList;
var fetchCharacterList = function () { return __awaiter(void 0, void 0, void 0, function () {
    var char_select, chardata, char_array, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                char_select = (document.getElementById("client_iniselect"));
                char_select.innerHTML = "";
                char_select.add(new Option("Custom", "0"));
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, (0, request_js_1.request)("".concat(aoHost_1.AO_HOST, "characters.json"))];
            case 2:
                chardata = _a.sent();
                char_array = JSON.parse(chardata);
                // the try catch will fail before here when there is no file
                char_array.forEach(function (character) {
                    char_select.add(new Option(character));
                });
                return [3 /*break*/, 4];
            case 3:
                err_2 = _a.sent();
                console.warn("there was no characters.json file");
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
exports.fetchCharacterList = fetchCharacterList;
var fetchEvidenceList = function () { return __awaiter(void 0, void 0, void 0, function () {
    var evi_select, evidata, evi_array, err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                evi_select = (document.getElementById("evi_select"));
                evi_select.innerHTML = "";
                evi_select.add(new Option("Custom", "0"));
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, (0, request_js_1.request)("".concat(aoHost_1.AO_HOST, "evidence.json"))];
            case 2:
                evidata = _a.sent();
                evi_array = JSON.parse(evidata);
                // the try catch will fail before here when there is no file
                evi_array.forEach(function (evi) {
                    evi_select.add(new Option(evi));
                });
                return [3 /*break*/, 4];
            case 3:
                err_3 = _a.sent();
                console.warn("there was no evidence.json file");
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
exports.fetchEvidenceList = fetchEvidenceList;
var fetchManifest = function () { return __awaiter(void 0, void 0, void 0, function () {
    var manifestdata, err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, (0, request_js_1.request)("".concat(aoHost_1.AO_HOST, "manifest.txt"))];
            case 1:
                manifestdata = _a.sent();
                client_1.client.manifest = manifestdata.split(/\r\n|\n\r|\n|\r/);
                return [3 /*break*/, 3];
            case 2:
                err_4 = _a.sent();
                console.warn("there was no manifest.txt file");
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.fetchManifest = fetchManifest;
