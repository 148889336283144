"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendSelf = void 0;
var client_1 = require("../../client");
/**
 * Hook for sending messages to the client
 * @param {string} message the message to send
 */
var sendSelf = function (message) {
    (document.getElementById("client_ooclog")).value += "".concat(message, "\r\n");
    client_1.client.handleSelf(message);
};
exports.sendSelf = sendSelf;
