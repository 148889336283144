"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleJD = void 0;
/**
* show/hide judge controls
* @param {number} show either a 1 or a 0
*/
var handleJD = function (args) {
    if (Number(args[1]) === 1) {
        document.getElementById("judge_action").style.display = "inline-table";
        document.getElementById("no_action").style.display = "none";
    }
    else {
        document.getElementById("judge_action").style.display = "none";
        document.getElementById("no_action").style.display = "inline-table";
    }
};
exports.handleJD = handleJD;
