"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleZZ = void 0;
var client_1 = require("../../client");
var aoHost_1 = require("../../client/aoHost");
var encoding_1 = require("../../encoding");
/**
 * Handles a modcall
 * @param {Array} args packet arguments
 */
var handleZZ = function (args) {
    var oocLog = document.getElementById("client_ooclog");
    oocLog.innerHTML += "$Alert: ".concat((0, encoding_1.prepChat)(args[1]), "\r\n");
    if (oocLog.scrollTop > oocLog.scrollHeight - 60) {
        oocLog.scrollTop = oocLog.scrollHeight;
    }
    client_1.client.viewport.getSfxAudio().pause();
    var oldvolume = client_1.client.viewport.getSfxAudio().volume;
    client_1.client.viewport.getSfxAudio().volume = 1;
    client_1.client.viewport.getSfxAudio().src = "".concat(aoHost_1.AO_HOST, "sounds/general/sfx-gallery.opus");
    client_1.client.viewport.getSfxAudio().play();
    client_1.client.viewport.getSfxAudio().volume = oldvolume;
};
exports.handleZZ = handleZZ;
