"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reloadTheme = void 0;
var client_1 = require("../client");
var setCookie_1 = require("../utils/setCookie");
/**
 * Triggered by the theme selector.
 */
var reloadTheme = function () {
    client_1.client.viewport.setTheme(document.getElementById("client_themeselect")
        .value);
    (0, setCookie_1.default)("theme", client_1.client.viewport.getTheme());
    (document.getElementById("client_theme")).href = "styles/".concat(client_1.client.viewport.getTheme(), ".css");
};
exports.reloadTheme = reloadTheme;
window.reloadTheme = exports.reloadTheme;
