"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveChatlogHandle = void 0;
var downloadFile_1 = require("../services/downloadFile");
var saveChatlogHandle = function () { return __awaiter(void 0, void 0, void 0, function () {
    var clientLog, icMessageLogs, messages, i, SHOWNAME_POSITION, TEXT_POSITION, showname, text, message, d, ye, mo, da, filename;
    return __generator(this, function (_a) {
        clientLog = document.getElementById("client_log");
        icMessageLogs = clientLog.getElementsByTagName("p");
        messages = [];
        for (i = 0; i < icMessageLogs.length; i++) {
            SHOWNAME_POSITION = 0;
            TEXT_POSITION = 2;
            showname = icMessageLogs[i].children[SHOWNAME_POSITION].innerHTML;
            text = icMessageLogs[i].children[TEXT_POSITION].innerHTML;
            message = "".concat(showname, ": ").concat(text);
            messages.push(message);
        }
        d = new Date();
        ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
        mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
        da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
        filename = "chatlog-".concat(da, "-").concat(mo, "-").concat(ye).toLowerCase();
        (0, downloadFile_1.default)(messages.join("\n"), filename);
        // Reset Chatbox to Empty
        document.getElementById("client_inputbox").value = "";
        return [2 /*return*/];
    });
}); };
exports.saveChatlogHandle = saveChatlogHandle;
