"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetICParams = resetICParams;
var client_1 = require("../client");
/**
 * Resets the IC parameters for the player to enter a new chat message.
 * This should only be called when the player's previous chat message
 * was successfully sent/presented.
 */
function resetICParams() {
    document.getElementById("client_inputbox").value = "";
    document.getElementById("button_flash").className = "client_button";
    document.getElementById("button_shake").className = "client_button";
    document.getElementById("sendpreanim").checked = false;
    document.getElementById("sendsfx").checked = false;
    if (client_1.selectedShout) {
        document.getElementById("button_".concat(client_1.selectedShout)).className =
            "client_button";
        (0, client_1.setSelectedShout)(0);
    }
}
