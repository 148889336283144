"use strict";
/* eslint indent: ["error", 2, { "SwitchCase": 1 }] */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var client_1 = require("../client");
var client_2 = require("../client");
var setEmote_1 = require("../client/setEmote");
var encoding_1 = require("../encoding");
var aoHost_1 = require("../client/aoHost");
var createBlipChannels_1 = require("./utils/createBlipChannels");
var defaultChatMsg_1 = require("./constants/defaultChatMsg");
var createMusic_1 = require("./utils/createMusic");
var createSfxAudio_1 = require("./utils/createSfxAudio");
var createShoutAudio_1 = require("./utils/createShoutAudio");
var createTestimonyAudio_1 = require("./utils/createTestimonyAudio");
var colors_1 = require("./constants/colors");
var setSide_1 = require("./utils/setSide");
var handleICSpeaking_1 = require("./utils/handleICSpeaking");
var viewport = function () {
    var animating = false;
    var blipChannels = (0, createBlipChannels_1.createBlipsChannels)();
    var chatmsg = defaultChatMsg_1.defaultChatMsg;
    var currentBlipChannel = 0;
    var lastChar = "";
    var lastEvi = 0;
    var music = (0, createMusic_1.createMusic)();
    var sfxAudio = (0, createSfxAudio_1.createSfxAudio)();
    var sfxplayed = 0;
    var shoutTimer = 0;
    var shoutaudio = (0, createShoutAudio_1.createShoutAudio)();
    var testimonyAudio = (0, createTestimonyAudio_1.createTestimonyAudio)();
    var testimonyTimer = 0;
    var testimonyUpdater;
    var textnow = "";
    var theme;
    var tickTimer = 0;
    var updater;
    var backgroundName = "";
    var getSfxAudio = function () { return sfxAudio; };
    var setSfxAudio = function (value) { sfxAudio = value; };
    var getBackgroundName = function () { return backgroundName; };
    var setBackgroundName = function (value) { backgroundName = value; };
    var getBackgroundFolder = function () {
        return "".concat(aoHost_1.AO_HOST, "background/").concat(encodeURI(backgroundName.toLowerCase()), "/");
    };
    var getTextNow = function () { return textnow; };
    var setTextNow = function (val) { textnow = val; };
    var getChatmsg = function () { return chatmsg; };
    var setChatmsg = function (val) { chatmsg = val; };
    var getSfxPlayed = function () { return sfxplayed; };
    var setSfxPlayed = function (val) { sfxplayed = val; };
    var getTickTimer = function () { return tickTimer; };
    var setTickTimer = function (val) { tickTimer = val; };
    var getAnimating = function () { return animating; };
    var setAnimating = function (val) { animating = val; };
    var getLastEvidence = function () { return lastEvi; };
    var setLastEvidence = function (val) { lastEvi = val; };
    var setLastCharacter = function (val) { lastChar = val; };
    var getLastCharacter = function () { return lastChar; };
    var getShoutTimer = function () { return shoutTimer; };
    var setShoutTimer = function (val) { shoutTimer = val; };
    var getTheme = function () { return theme; };
    var setTheme = function (val) { theme = val; };
    var getTestimonyTimer = function () { return testimonyTimer; };
    var setTestimonyTimer = function (val) { testimonyTimer = val; };
    var setTestimonyUpdater = function (val) { testimonyUpdater = val; };
    var getTestimonyUpdater = function () { return testimonyUpdater; };
    var playSFX = function (sfxname, looping) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            sfxAudio.pause();
            sfxAudio.loop = looping;
            sfxAudio.src = sfxname;
            sfxAudio.play();
            return [2 /*return*/];
        });
    }); };
    /**
     * Updates the testimony overaly
     */
    var updateTestimony = function () {
        var testimonyFilenames = {
            1: "witnesstestimony",
            2: "crossexamination",
            3: "notguilty",
            4: "guilty",
        };
        // Update timer
        testimonyTimer += client_2.UPDATE_INTERVAL;
        var testimony = testimonyFilenames[client_1.client.testimonyID];
        var resource = client_1.client.resources[testimony];
        if (!resource) {
            disposeTestimony();
            return;
        }
        if (testimonyTimer >= resource.duration) {
            disposeTestimony();
        }
        else {
            testimonyUpdater = setTimeout(function () { return updateTestimony(); }, client_2.UPDATE_INTERVAL);
        }
    };
    /**
     * Dispose the testimony overlay
     */
    var disposeTestimony = function () {
        client_1.client.testimonyID = 0;
        testimonyTimer = 0;
        document.getElementById("client_testimony").style.opacity = "0";
        clearTimeout(testimonyUpdater);
    };
    var handleTextTick = function (charLayers) { return __awaiter(void 0, void 0, void 0, function () {
        var chatBox, waitingBox, chatBoxInner, charName, charEmote, characterElement, COMMAND_IDENTIFIER, nextCharacterElement, flash, shake, commands, textSpeeds, MAX_SLOW_CHATSPEED, i, currentCharacter;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    chatBox = document.getElementById("client_chat");
                    waitingBox = document.getElementById("client_chatwaiting");
                    chatBoxInner = document.getElementById("client_inner_chat");
                    charName = chatmsg.name.toLowerCase();
                    charEmote = chatmsg.sprite.toLowerCase();
                    if (chatmsg.content.charAt(textnow.length) !== " ") {
                        blipChannels[currentBlipChannel].play();
                        currentBlipChannel++;
                        currentBlipChannel %= blipChannels.length;
                    }
                    textnow = chatmsg.content.substring(0, textnow.length + 1);
                    characterElement = chatmsg.parsed[textnow.length - 1];
                    if (!characterElement) return [3 /*break*/, 3];
                    COMMAND_IDENTIFIER = "\\";
                    nextCharacterElement = chatmsg.parsed[textnow.length];
                    flash = function () { return __awaiter(void 0, void 0, void 0, function () {
                        var effectlayer;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    effectlayer = document.getElementById("client_fg");
                                    playSFX("".concat(aoHost_1.AO_HOST, "sounds/general/sfx-realization.opus"), false);
                                    effectlayer.style.animation = "flash 0.4s 1";
                                    return [4 /*yield*/, (0, client_1.delay)(400)];
                                case 1:
                                    _a.sent();
                                    effectlayer.style.removeProperty("animation");
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    shake = function () { return __awaiter(void 0, void 0, void 0, function () {
                        var gamewindow;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    gamewindow = document.getElementById("client_gamewindow");
                                    playSFX("".concat(aoHost_1.AO_HOST, "sounds/general/sfx-stab.opus"), false);
                                    gamewindow.style.animation = "shake 0.2s 1";
                                    return [4 /*yield*/, (0, client_1.delay)(200)];
                                case 1:
                                    _a.sent();
                                    gamewindow.style.removeProperty("animation");
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    commands = new Map(Object.entries({
                        s: shake,
                        f: flash,
                    }));
                    textSpeeds = new Set(["{", "}"]);
                    // Changing Text Speed
                    if (textSpeeds.has(characterElement.innerHTML)) {
                        MAX_SLOW_CHATSPEED = 120;
                        for (i = textnow.length; i < chatmsg.content.length; i++) {
                            currentCharacter = chatmsg.parsed[i - 1].innerHTML;
                            if (currentCharacter === "}") {
                                if (chatmsg.speed > 0) {
                                    chatmsg.speed -= 20;
                                }
                            }
                            else if (currentCharacter === "{") {
                                if (chatmsg.speed < MAX_SLOW_CHATSPEED) {
                                    chatmsg.speed += 20;
                                }
                            }
                            else {
                                // No longer at a speed character
                                textnow = chatmsg.content.substring(0, i);
                                break;
                            }
                        }
                    }
                    if (!(characterElement.innerHTML === COMMAND_IDENTIFIER &&
                        commands.has(nextCharacterElement === null || nextCharacterElement === void 0 ? void 0 : nextCharacterElement.innerHTML))) return [3 /*break*/, 2];
                    textnow = chatmsg.content.substring(0, textnow.length + 1);
                    return [4 /*yield*/, commands.get(nextCharacterElement.innerHTML)()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    chatBoxInner.appendChild(chatmsg.parsed[textnow.length - 1]);
                    _a.label = 3;
                case 3:
                    // scroll to bottom
                    chatBox.scrollTop = chatBox.scrollHeight;
                    if (textnow === chatmsg.content) {
                        animating = false;
                        (0, setEmote_1.default)(aoHost_1.AO_HOST, client_1.client, charName, charEmote, "(a)", false, chatmsg.side);
                        charLayers.style.opacity = "1";
                        waitingBox.style.opacity = "1";
                        clearTimeout(updater);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    /**
     * Updates the chatbox based on the given text.
     *
     * OK, here's the documentation on how this works:
     *
     * 1 _animating
     * If we're not done with this characters animation, i.e. his text isn't fully there, set a timeout for the next tick/step to happen
     *
     * 2 startpreanim
     * If the shout timer is over it starts with the preanim
     * The first thing it checks for is the shake effect (TODO on client this is handled by the @ symbol and not a flag )
     * Then is the flash/realization effect
     * After that, the shout image set to be transparent
     * and the main characters preanim gif is loaded
     * If pairing is supported the paired character will just stand around with his idle sprite
     *
     * 3 preanimdelay over
     * this animates the evidence popup and finally shows the character name and message box
     * it sets the text color and the character speaking sprite
     *
     * 4 textnow != content
     * this adds a character to the textbox and stops the animations if the entire message is present in the textbox
     *
     * 5 sfx
     * independent of the stuff above, this will play any sound effects specified by the emote the character sent.
     * happens after the shout delay + an sfx delay that comes with the message packet
     *
     * XXX: This relies on a global variable `chatmsg`!
     */
    var chat_tick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var gamewindow, waitingBox, eviBox, shoutSprite, effectlayer, chatBoxInner, charLayers, pairLayers, validSides, charName, charEmote, pairName, pairEmote, isShoutOver, isShoutAndPreanimOver, preanim, hasNonInterruptingPreAnim, chatContainerBox, chatContainerBox;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: 
                // note: this is called fairly often
                // do not perform heavy operations here
                return [4 /*yield*/, (0, client_1.delay)(chatmsg.speed)];
                case 1:
                    // note: this is called fairly often
                    // do not perform heavy operations here
                    _a.sent();
                    gamewindow = document.getElementById("client_gamewindow");
                    waitingBox = document.getElementById("client_chatwaiting");
                    eviBox = document.getElementById("client_evi");
                    shoutSprite = (document.getElementById("client_shout"));
                    effectlayer = document.getElementById("client_fg");
                    chatBoxInner = document.getElementById("client_inner_chat");
                    charLayers = document.getElementById("client_char");
                    pairLayers = (document.getElementById("client_pair_char"));
                    validSides = ["def", "pro", "wit"];
                    if (validSides.includes(chatmsg.side)) {
                        charLayers = (document.getElementById("client_".concat(chatmsg.side, "_char")));
                        pairLayers = (document.getElementById("client_".concat(chatmsg.side, "_pair_char")));
                    }
                    charName = chatmsg.name.toLowerCase();
                    charEmote = chatmsg.sprite.toLowerCase();
                    pairName = chatmsg.other_name.toLowerCase();
                    pairEmote = chatmsg.other_emote.toLowerCase();
                    isShoutOver = tickTimer >= shoutTimer;
                    isShoutAndPreanimOver = tickTimer >= shoutTimer + chatmsg.preanimdelay;
                    if (isShoutOver && handleICSpeaking_1.startFirstTickCheck) {
                        // Effect stuff
                        if (chatmsg.screenshake === 1) {
                            // Shake screen
                            playSFX("".concat(aoHost_1.AO_HOST, "sounds/general/sfx-stab.opus"), false);
                            gamewindow.style.animation = "shake 0.2s 1";
                        }
                        if (chatmsg.flash === 1) {
                            // Flash screen
                            playSFX("".concat(aoHost_1.AO_HOST, "sounds/general/sfx-realization.opus"), false);
                            effectlayer.style.animation = "flash 0.4s 1";
                        }
                        // Pre-animation stuff
                        if (chatmsg.preanimdelay > 0) {
                            shoutSprite.style.opacity = "0";
                            shoutSprite.style.animation = "";
                            preanim = chatmsg.preanim.toLowerCase();
                            (0, setEmote_1.default)(aoHost_1.AO_HOST, client_1.client, charName, preanim, "", false, chatmsg.side);
                        }
                        if (chatmsg.other_name) {
                            pairLayers.style.opacity = "1";
                        }
                        else {
                            pairLayers.style.opacity = "0";
                        }
                        // Done with first check, move to second
                        (0, handleICSpeaking_1.setStartFirstTickCheck)(false);
                        (0, handleICSpeaking_1.setStartSecondTickCheck)(true);
                        chatmsg.startpreanim = false;
                        chatmsg.startspeaking = true;
                    }
                    hasNonInterruptingPreAnim = chatmsg.noninterrupting_preanim === 1;
                    if (!(textnow !== chatmsg.content && hasNonInterruptingPreAnim)) return [3 /*break*/, 3];
                    chatContainerBox = document.getElementById("client_chatcontainer");
                    chatContainerBox.style.opacity = "1";
                    return [4 /*yield*/, handleTextTick(charLayers)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 3:
                    if (!(isShoutAndPreanimOver && handleICSpeaking_1.startSecondTickCheck)) return [3 /*break*/, 6];
                    if (!chatmsg.startspeaking) return [3 /*break*/, 4];
                    chatmsg.startspeaking = false;
                    // Evidence Bullshit
                    if (chatmsg.evidence > 0) {
                        // Prepare evidence
                        eviBox.src = (0, encoding_1.safeTags)(client_1.client.evidences[chatmsg.evidence - 1].icon);
                        eviBox.style.width = "auto";
                        eviBox.style.height = "36.5%";
                        eviBox.style.opacity = "1";
                        testimonyAudio.src = "".concat(aoHost_1.AO_HOST, "sounds/general/sfx-evidenceshoop.opus");
                        testimonyAudio.play();
                        if (chatmsg.side === "def") {
                            // Only def show evidence on right
                            eviBox.style.right = "1em";
                            eviBox.style.left = "initial";
                        }
                        else {
                            eviBox.style.right = "initial";
                            eviBox.style.left = "1em";
                        }
                    }
                    chatBoxInner.className = "text_".concat(colors_1.COLORS[chatmsg.color]);
                    if (chatmsg.preanimdelay === 0) {
                        shoutSprite.style.opacity = "0";
                        shoutSprite.style.animation = "";
                    }
                    switch (Number(chatmsg.deskmod)) {
                        case 2:
                            (0, setSide_1.set_side)({
                                position: chatmsg.side,
                                showSpeedLines: false,
                                showDesk: true,
                            });
                            break;
                        case 3:
                            (0, setSide_1.set_side)({
                                position: chatmsg.side,
                                showSpeedLines: false,
                                showDesk: false,
                            });
                            break;
                        case 4:
                            (0, setSide_1.set_side)({
                                position: chatmsg.side,
                                showSpeedLines: false,
                                showDesk: true,
                            });
                            break;
                        case 5:
                            (0, setSide_1.set_side)({
                                position: chatmsg.side,
                                showSpeedLines: false,
                                showDesk: false,
                            });
                            break;
                    }
                    if (chatmsg.other_name) {
                        (0, setEmote_1.default)(aoHost_1.AO_HOST, client_1.client, pairName, pairEmote, "(a)", true, chatmsg.side);
                        pairLayers.style.opacity = "1";
                    }
                    else {
                        pairLayers.style.opacity = "0";
                    }
                    (0, setEmote_1.default)(aoHost_1.AO_HOST, client_1.client, charName, charEmote, "(b)", false, chatmsg.side);
                    charLayers.style.opacity = "1";
                    if (textnow === chatmsg.content) {
                        (0, setEmote_1.default)(aoHost_1.AO_HOST, client_1.client, charName, charEmote, "(a)", false, chatmsg.side);
                        charLayers.style.opacity = "1";
                        waitingBox.style.opacity = "1";
                        animating = false;
                        clearTimeout(updater);
                        return [2 /*return*/];
                    }
                    return [3 /*break*/, 6];
                case 4:
                    if (!(textnow !== chatmsg.content)) return [3 /*break*/, 6];
                    chatContainerBox = document.getElementById("client_chatcontainer");
                    chatContainerBox.style.opacity = "1";
                    return [4 /*yield*/, handleTextTick(charLayers)];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6:
                    if (!sfxplayed && chatmsg.snddelay + shoutTimer >= tickTimer) {
                        sfxplayed = 1;
                        if (chatmsg.sound !== "0" &&
                            chatmsg.sound !== "1" &&
                            chatmsg.sound !== "" &&
                            chatmsg.sound !== undefined &&
                            (chatmsg.type == 1 || chatmsg.type == 2 || chatmsg.type == 6)) {
                            playSFX("".concat(aoHost_1.AO_HOST, "sounds/general/").concat(encodeURI(chatmsg.sound.toLowerCase()), ".opus"), chatmsg.looping_sfx);
                        }
                    }
                    if (textnow === chatmsg.content) {
                        return [2 /*return*/];
                    }
                    if (animating) {
                        chat_tick();
                    }
                    tickTimer += client_2.UPDATE_INTERVAL;
                    return [2 /*return*/];
            }
        });
    }); };
    return {
        getTextNow: getTextNow,
        setTextNow: setTextNow,
        getChatmsg: getChatmsg,
        setChatmsg: setChatmsg,
        getSfxPlayed: getSfxPlayed,
        setSfxPlayed: setSfxPlayed,
        setTickTimer: setTickTimer,
        getTickTimer: getTickTimer,
        setAnimating: setAnimating,
        getAnimating: getAnimating,
        getLastEvidence: getLastEvidence,
        setLastEvidence: setLastEvidence,
        setLastCharacter: setLastCharacter,
        getLastCharacter: getLastCharacter,
        getShoutTimer: getShoutTimer,
        setShoutTimer: setShoutTimer,
        setTheme: setTheme,
        getTheme: getTheme,
        setTestimonyTimer: setTestimonyTimer,
        getTestimonyTimer: getTestimonyTimer,
        setTestimonyUpdater: setTestimonyUpdater,
        getTestimonyUpdater: getTestimonyUpdater,
        testimonyAudio: testimonyAudio,
        chat_tick: chat_tick,
        playSFX: playSFX,
        set_side: setSide_1.set_side,
        setBackgroundName: setBackgroundName,
        updateTestimony: updateTestimony,
        disposeTestimony: disposeTestimony,
        handleTextTick: handleTextTick,
        getBackgroundFolder: getBackgroundFolder,
        getBackgroundName: getBackgroundName,
        getSfxAudio: getSfxAudio,
        setSfxAudio: setSfxAudio,
        blipChannels: blipChannels,
        music: music,
        shoutaudio: shoutaudio,
        updater: updater,
    };
};
exports.default = viewport;
