"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendServer = void 0;
var client_1 = require("../../client");
var queryParser_1 = require("../../utils/queryParser");
var mode = (0, queryParser_1.default)().mode;
/**
 * Hook for sending messages to the server
 * @param {string} message the message to send
 */
var sendServer = function (message) {
    console.debug("C: " + message);
    mode === "replay" ? client_1.client.sender.sendSelf(message) : client_1.client.serv.send(message);
};
exports.sendServer = sendServer;
