"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showname_click = showname_click;
var setCookie_1 = require("../utils/setCookie");
/**
 * Triggered when the showname checkboc is clicked
 * @param {MouseEvent} event
 */
function showname_click(_event) {
    (0, setCookie_1.default)("showname", String(document.getElementById("showname").checked));
    (0, setCookie_1.default)("ic_chat_name", document.getElementById("ic_chat_name").value);
    var css_s = document.getElementById("nameplate_setting");
    if (document.getElementById("showname").checked) {
        css_s.href = "styles/shownames.css";
    }
    else {
        css_s.href = "styles/nameplates.css";
    }
}
window.showname_click = showname_click;
