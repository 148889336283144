"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLORS = void 0;
exports.COLORS = [
    "white",
    "green",
    "red",
    "orange",
    "blue",
    "yellow",
    "pink",
    "cyan",
    "grey",
    "rainbow",
];
