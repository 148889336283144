"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleEI = void 0;
var client_1 = require("../../client");
var aoHost_1 = require("../../client/aoHost");
var encoding_1 = require("../../encoding");
/**
 * Handles incoming evidence information, containing only one evidence
 * item per packet.
 *
 * EI#id#name&description&type&image&##%
 *
 * @param {Array} args packet arguments
 */
var handleEI = function (args) {
    document.getElementById("client_loadingtext").innerHTML = "Loading Evidence ".concat(args[1], "/").concat(client_1.client.evidence_list_length);
    var evidenceID = Number(args[1]);
    var arg = args[2].split("&");
    client_1.client.evidences[evidenceID] = {
        name: (0, encoding_1.prepChat)(arg[0]),
        desc: (0, encoding_1.prepChat)(arg[1]),
        filename: (0, encoding_1.safeTags)(arg[3]),
        icon: "".concat(aoHost_1.AO_HOST, "evidence/").concat(encodeURI(arg[3].toLowerCase())),
    };
    client_1.client.sender.sendServer("AE" + (evidenceID + 1) + "#%");
};
exports.handleEI = handleEI;
